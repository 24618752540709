import styled from 'styled-components'
import Media from 'utils/media'

export const HeaderStyles = styled.div`
  height: 92px;

  padding: 20px 24px;

  width: 100%;
  .how-to-play {
    display: block;
    ${Media.lessThan(Media.SIZE.MD)} {
      display: none;
    }
  }
  ${Media.greaterThan(Media.SIZE.MD)} {
    background: linear-gradient(90deg, #060027 0%, rgba(0, 50, 130, 0.5) 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    padding: 24px 40px;
  }
  ${Media.lessThan(Media.SIZE.XS1)} {
    padding: 10px;
  }
  display: flex;
  .icon__menu__mobile {
    display: block;
    ${Media.greaterThan(Media.SIZE.MD)} {
      display: none;
    }
  }
  .group__header__menu {
    display: flex;
    align-items: center;
    gap: 20px !important;
    ${Media.lessThan(Media.SIZE.MD)} {
      z-index: 9999;
    }
    .logo {
      margin-right: 20px;
      object-fit: cover;
      width: 67.43px;
      height: 44px;
      ${Media.greaterThan(Media.SIZE.MD)} {
        margin-right: 50px;
        width: 90px;
        height: 58px;
      }
      ${Media.lessThan(Media.SIZE.XS1)} {
        width: 56px;
        height: 36px;
        object-fit: cover;
      }
    }
  }
  .navbar-list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__item {
      display: flex;
      align-items: center;
      position: relative;
      min-height: 92px;
      ${Media.lessThan(Media.SIZE.MD)} {
        display: none;
      }

      &__link {
        margin-right: 50px;
        color: white;
        position: relative;
        height: 92px;
        display: flex;
        align-items: center;
        a {
          &::before {
            content: '';
            width: 100%;
            background: linear-gradient(178.97deg, #f7931e 3.38%, #fbcd23 77.84%, #ffff27 141.41%);
            height: 3px;
            position: absolute;
            top: -3px;
          }
        }
        a.active {
          background: -webkit-linear-gradient(178.97deg, #f7931e 3.38%, #fbcd23 77.84%, #ffff27 141.41%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          &::before {
            content: '';
            width: 30px;
            left: 50%;
            border-radius: 0 0 5px 5px;
            transform: translateX(-50%);
            background: linear-gradient(178.97deg, #f7931e 3.38%, #fbcd23 77.84%, #ffff27 141.41%);
            height: 4px;
            position: absolute;
            top: 0;
          }
        }
      }
    }

    ul > li > a {
      font-family: 'UTM Bebas';
      color: inherit;
      font-weight: 400;
      font-size: 17px;
    }
  }
`
export const MainDivStyles = styled.div`
  background: url('/mera/bgapp.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  position: relative;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  main {
    display: flex;
    justify-content: space-between;
  }
  .navbar-list__item__link {
    position: relative;
    & > a:before {
      content: '';
      width: 100%;
      background: linear-gradient(178.97deg, #f7931e 3.38%, #fbcd23 77.84%, #ffff27 141.41%);
      height: 3px;
      position: absolute;
      top: -3px;
    }
    & > a {
      span.play-now {
        width: max-content;
        position: absolute;
        font-size: 11px;
        left: 13px;
        top: 24px;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 900;
        --bg-size: 200%;
        --color-one: rgb(56, 189, 248);
        --color-two: rgb(251, 113, 133);
        --color-three: rgb(163, 230, 53);
        background: linear-gradient(90deg, var(--color-one), var(--color-two), var(--color-three)) 0 0 / var(--bg-size)
          100%;
        color: transparent;

        background-clip: text;
        -webkit-background-clip: text;
        animation: move-bg 2s infinite linear;
        @media (prefers-reduced-motion: no-preference) {
          animation: move-bg 1s linear infinite;
          @keyframes move-bg {
            to {
              background-position: 200% 0;
            }
          }
        }
      }
    }
  }
`
export const ActionCenterStyles = styled.div`
  flex: 1;
  display: flex;
  z-index: 1000;
  align-items: center;
  justify-content: flex-end;
  gap: 8px !important;
  & > button:not(:last-child) {
    ${Media.lessThan(Media.SIZE.MD)} {
      display: none !important;
    }
  }

  ${Media.greaterThan(Media.SIZE.MD)} {
    gap: 35px !important;
  }
  ${Media.lessThan(Media.SIZE.XS1)} {
    gap: 10px !important;
  }

  .how-to-play {
    text-align: right;
    color: #fff;
    font-family: 'UTM Bebas';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }

  .user__header__icon,
  .user__header__icon__mobile {
    display: flex;
    align-items: center;
    & > img {
      width: 32px;
      height: 32px;
      ${Media.greaterThan(Media.SIZE.MD)} {
        width: 44px;
        height: 44px;
      }
    }
    transition: all 0.3s ease-in-out;
    &:hover {
      opacity: 0.8;
    }
  }
  .user__header__icon {
    display: flex;
    margin-right: 20px;
    ${Media.greaterThan(Media.SIZE.MD)} {
      display: flex;
      margin-right: 0;
    }
  }
  .user__header__icon__mobile {
    display: none;
    ${Media.greaterThan(Media.SIZE.MD)} {
      display: none;
    }
  }
`

export const SidebarMobileLeftStyles = styled.div<any>`
  .dropdown__link--mobile {
    position: relative;
    font-family: 'UTM Bebas';
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 18px;
    color: #ffff;
    cursor: pointer;
    .icon-dropdown-status {
      position: absolute;
      left: 40%;
      top: 3px;
      width: 15px;
      height: 15px;
      display: flex;
    }
  }
  padding-top: 92px;

  ${({ showMenuLeft }) => (showMenuLeft ? ' padding-left:49px' : ' padding-left:0px')};

  ${({ showMenuLeft }) => (showMenuLeft ? ' padding-left:49px' : ' padding-left:0px')};

  width: 200px;
  position: fixed;
  top: 0;

  ${({ showMenuLeft }) => (showMenuLeft ? 'left:0' : '')};
  ${({ showMenuLeft }) => showMenuLeft && 'transform:translateX(0)'};

  ${({ showMenuLeft }) => !showMenuLeft && 'transform:translateX(-200%)'};
  transition: all 0.3s ease-in-out;
  background: linear-gradient(296.82deg, rgba(5, 4, 90, 0.9) 9.08%, rgba(83, 0, 75, 0.6) 88.11%);
  mix-blend-mode: normal;
  backdrop-filter: blur(15px);
  z-index: 900;
  max-width: 250px;
  height: 100vh;
  .overlay__sb__mobile {
    position: absolute;
    min-width: 1000%;
  }
  .sidebar-list__mobile.left {
    .sidebar-list__mobile__item {
      a.active {
        color: #ffce5f;
        .icon__active {
          display: block;
        }
      }
    }
  }
  .sidebar-list__mobile.right {
    .btn_logout_big {
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 20px;
      color: white;
      font-family: 'UTM Bebas';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;

      height: 44px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 12px;
      gap: 20px;
    }
    .sidebar-list__mobile__item {
      display: flex;
      justify-content: flex-end;
    }
  }
  .sidebar-list__mobile {
    margin-top: 50px;

    &__item {
      margin-bottom: 28px;
      & > a {
        position: relative;
        font-family: 'UTM Bebas';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: center;
        gap: 8px;
        color: #ffffff;
        .icon__active {
          position: absolute;
          left: -25px;
          top: 50%;
          transform: translateY(-50%);
          display: none;
        }
      }
      a.left.active {
        color: #ffce5f;
        img {
          color: #ffce5f;
          background-image: #ffce5f;
        }
        .icon__active {
          display: block;
        }
      }
    }
  }

  ${Media.between(Media.SIZE.MD, Media.SIZE.MD)} {
    width: 250px;
  }
  @media only screen and (min-width: 769px) {
    display: none;
  }
  .sidebar_left__overlay {
    content: '';
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.45);
    width: 10000%;
    z-index: 11;
    display: ${({ showMenuLeft }) => (showMenuLeft ? 'block' : 'none')};
    ${({ showMenuLeft }) => (showMenuLeft ? 'left: 100%;' : 'right: 100%;')};

    height: 100vh;
  }
`
export const SidebarMobileRightStyles = styled.div<any>`
  padding-top: 92px;
  ${({ showMenuRight }) => (showMenuRight ? ' transform: translateX(0)' : ' transform: translateX(250px)')};

  padding-right: 49px;
  width: 200px;
  position: fixed;
  top: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(296.82deg, rgba(5, 4, 90, 0.9) 9.08%, rgba(83, 0, 75, 0.6) 88.11%);
  mix-blend-mode: normal;
  backdrop-filter: blur(15px);
  z-index: 900;
  max-width: 250px;
  height: 100vh;
  .overlay__sb__mobile {
    position: absolute;
    min-width: 1000%;
  }
  .btn_logout_big {
    background: linear-gradient(181.67deg, #663ef5 1.44%, #ff00d6 185.5%);
  }
  .sidebar-list__mobile.right {
    .btn_logout_big {
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 20px;
      color: white;
      font-family: 'UTM Bebas';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;

      height: 44px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 12px;
      gap: 20px;
    }
    .sidebar-list__mobile__item {
      display: flex;
      justify-content: flex-end;
      & > a {
        & > img {
          display: none;
        }
      }
      & > a.active {
        & > img {
          display: block;
        }
      }
    }
  }
  .sidebar-list__mobile {
    margin-top: 50px;

    &__item {
      margin-bottom: 28px;
      & > a {
        position: relative;
        font-family: 'UTM Bebas';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: center;
        gap: 8px;
        color: #ffffff;
        .icon__active {
          position: absolute;
          left: -25px;
          top: 50%;
          transform: translateY(-50%);
          display: none;
        }
      }
      a.left.active {
        color: #ffce5f;
        img {
          color: #ffce5f;
          background-image: #ffce5f;
        }
        .icon__active {
          display: block;
        }
      }
    }
  }

  ${Media.between(Media.SIZE.MD, Media.SIZE.MD)} {
    width: 250px;
  }
  @media only screen and (min-width: 769px) {
    display: none;
  }
  .sidebar_right__overlay {
    position: absolute;
    top: 0;

    display: ${({ showMenuRight }) => (showMenuRight ? 'block' : 'none')};
    right: 250px;
    ${Media.lessThan(Media.SIZE.MD)} {
      right: 200px;
    }
    ${Media.between(Media.SIZE.MD, Media.SIZE.MD)} {
      right: 250px;
    }
    background-color: rgba(0, 0, 0, 0.45);

    width: 10000%;
    z-index: 11;

    height: 100vh;
  }
`
export const SidebarStyles = styled.div`
  padding-top: 52px;
  padding-left: 40px;
  width: 250px;
  position: relative;

  background: linear-gradient(90deg, #060027 0%, rgba(0, 50, 130, 0.5) 100%);

  height: calc(100vh - 92px);
  overflow: hidden;
  ul.sidebar__list {
    list-style: none;
    li.sidebar__list__item {
      margin-bottom: 28px;

      a {
        color: white;
        font-family: 'UTM Bebas';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        display: flex;

        .icon_arrow_blue {
          display: none;
          margin-right: 0;
        }
      }
      a.active {
        .icon_arrow_blue {
          display: block;
          margin-right: 20px;
          animation: margin 0.3s ease-in-out;
          @keyframes margin {
            from {
              margin-right: 0;
            }
            to {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
  .btn_logout_big {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    color: white;
    font-family: 'UTM Bebas';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    background: linear-gradient(181.67deg, #663ef5 1.44%, #ff00d6 185.5%);

    height: 44px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 107px;
  }
  ${Media.lessThan(Media.SIZE.MD)} {
    display: none;
  }
`

export const ContentStyles = styled.div`
  width: 100%;
  height: calc(100vh - 116px);
`
