import React from 'react'
import { LinkExternal, Flex } from '@pancakeswap/uikit'
import { useLogin } from 'hooks/useAuth'
import ModalAccount from 'components/Modal/ModalAccount'
import Typography from 'components/Typography'
import Button from 'components/Button/Button'
import styled from 'styled-components'
import Media from 'utils/media'
import Misc from 'utils/misc'
import CopyToClipboard from './CopyToClipboard'

interface Props {
  account: string
  onDismiss?: () => void
}

const AccountModal: React.FC<Props> = ({ account, onDismiss = () => null }) => {
  const { logout } = useLogin()

  return (
    <ModalAccount title="YOUR WALLET" onDismiss={onDismiss}>
      <Typography
        title={account}
        size="large"
        bold
        style={
          Misc.isMobile
            ? {
                wordBreak: 'normal',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }
            : {}
        }
      >
        {account}
      </Typography>
      <ActionsStyled mt="10px" mb="32px">
        <LinkExternal small href={`https://bscscan.com/address/${account}`}>
          View on BscScan
        </LinkExternal>
        <CopyToClipboard toCopy={account}>Copy Address</CopyToClipboard>
      </ActionsStyled>
      <Flex justifyContent="center">
        <Button
          styles="normal"
          rounded
          onClick={() => {
            logout()

            onDismiss()
          }}
        >
          Logout
        </Button>
      </Flex>
    </ModalAccount>
  )
}

const ActionsStyled = styled(Flex)<any>`
  margin: 28px auto;
  a,
  & > div {
    color: white !important;
    font-weight: normal;
    svg {
      fill: white !important;
    }
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    margin-top: 20px;
    flex-direction: column;
    gap: 10px;
  }
`

export default AccountModal
