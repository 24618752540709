import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import Media from 'utils/media'
import { COLORS } from 'utils'
import { Box } from '@pancakeswap/uikit'
import Typography from '../Typography'

const StyledButton = styled.button<ButtonProps>`
  overflow: hidden;
  position: relative;
  background: ${({ background, secondary, borderColor }) => (secondary ? COLORS.BLUE : borderColor || background)};
  width: ${({ styles }: any) => styles === 'normal' && '100% !important'};
  border-radius: ${({ styles }: any) => styles === 'normal' && '4px !important'};
  /* clip-path: ${({ styles }: any) => styles === 'normal' && 'none !important'}; */
  opacity: ${({ inactive }) => (inactive ? 0.5 : 1)};
  background-position: center;
  background-size: cover;
  padding: 6px 18px;

  height: 45px;
  min-width: 140px;
  transition: opacity 0.2s;
  outline: none;
  border: none;

  display: flex;
  /* --clip-width: 16px;
  clip-path: polygon(100% 0, 100% 60%, calc(100% - var(--clip-width)) 100%, 0% 100%, 0 40%, var(--clip-width) 0); */
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 300;

  position: relative;

  p {
    font-size: 16px;
    letter-spacing: 0.02em;
    color: ${({ color, secondary, disabled }) =>
      disabled ? '#656565' : secondary ? COLORS.BLUE : color || COLORS.TEXT_PRIMARY};
    font-family: 'UTM Bebas';
  }

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }

  &.small {
    height: 30px;
  }

  &.large {
    height: 50px;
    padding: 0 38px;

    p {
      font-size: 20px;
    }
  }

  /* &.rounded {
    border-radius: 50px;
  } */

  &.square {
    width: 36px;

    &.small {
      width: 30px;
    }
  }

  &.loading {
    cursor: wait;

    &::after,
    &::before {
      display: flex;
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: #656565;
    background: #c6c6c6;
  }

  &::before {
    background-color: ${({ background }) => background};
    content: '';
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }

  &::after {
    animation: rotation 0.7s infinite linear;
    content: '';
    border: 2px solid #200040;
    width: 20px;
    height: 20px;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    border-radius: 50%;
    position: absolute;
    border-bottom-color: transparent;
    border-top-color: transparent;
  }

  &::after,
  &::before {
    position: absolute;
    display: none;
  }

  &.shadow {
    box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.15);

    &::before {
      box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.15);
    }
  }

  ${Media.lessThan(Media.SIZE.MD)} {
    height: 45px;
    min-width: 140px;

    &.square {
      width: 33px;
    }

    &.large {
      height: 36px;
      padding: 0 12px;

      p {
        font-size: 14px;
      }
    }
  }
`

const StyledBorderRight = styled(Box)<ButtonProps>`
  content: '';
  position: absolute;
  z-index: -1;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;

  background: ${({ background, secondary }) => (secondary ? COLORS.BLUE : background)};
  background-position: center;
  background-size: cover;
  background-color: ${({ borderColor, secondary, disabled }) =>
    disabled ? '#C6C6C6' : secondary ? '#fff' : borderColor || COLORS.BLUE};
  /* --clip-width: 16px;
  clip-path: polygon(100% 0, 100% 60%, calc(100% - var(--clip-width)) 100%, 0% 100%, 0 40%, var(--clip-width) 0); */
`

type ButtonProps = React.HTMLAttributes<HTMLElement> & {
  // behavior:
  disabled?: boolean
  loading?: boolean
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void

  // styles:
  className?: string
  style?: React.CSSProperties
  size?: 'small' | 'large'
  background?: string
  color?: string
  rounded?: boolean
  square?: boolean
  shadow?: boolean
  secondary?: boolean
  inactive?: boolean
  borderColor?: string
  styles?: string
  // components:
  icon?: React.ReactNode | string
  children?: React.ReactNode
}

const Button = (props: ButtonProps) => {
  const { disabled, loading, onClick, className, size, rounded, square, shadow, icon, children, styles, ...rest } =
    props

  return (
    <StyledButton
      {...rest}
      styles={styles}
      disabled={disabled}
      className={classnames(
        size,
        {
          loading,
          rounded,
          square,
          shadow,
        },
        'button',
        className,
      )}
      onClick={loading || disabled ? null : onClick}
    >
      {icon && typeof icon === 'string' ? <img src={icon} alt="Button defi warrior" /> : icon}
      <Typography>{children}</Typography>
      {/* {/* <StyledBorderLeft borderColor={rest?.borderColor} /> */}
      <StyledBorderRight disabled={disabled} {...rest} />
    </StyledButton>
  )
}

Button.defaultProps = {
  background: COLORS.BLUE,
  shadow: false,
  rounded: false,
}

export default Button
