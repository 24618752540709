import { useWeb3React } from '@web3-react/core'
import { sidebarWithRoute } from 'config/constants/menu'
import { useLogin } from 'hooks/useAuth'
import React, { useCallback } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Balance from '../../Balance'
import { SidebarStyles } from '../../styled'

interface SidebarProps {
  renderByPathName: string
}

const Sidebar = ({ renderByPathName: pathName }: SidebarProps) => {
  const { logout: callLogout } = useLogin()
  const navigate = useNavigate()
  const { account } = useWeb3React()

  const handleLogOut = useCallback(() => {
    callLogout()
    navigate('/')
  }, [callLogout, navigate])

  return (
    <SidebarStyles>
      <ul className="sidebar__list">
        {sidebarWithRoute[`${pathName.toUpperCase()}`].map((item) => (
          <li key={item.title} className="sidebar__list__item">
            <NavLink
              end
              className={({ isActive }) => (isActive ? 'active' : 'inactive')}
              style={{ color: 'white' }}
              to={`${pathName}${item.pathName}`}
            >
              <img src="/mera/arrow_blue.svg" alt="icon" className="icon_arrow_blue" />
              {item.title}
            </NavLink>
          </li>
        ))}
      </ul>
      {account && <Balance />}

      <button type="button" className="btn_logout_big" onClick={handleLogOut}>
        Log out
        <img src="/mera/icon_logout.svg" alt="" />
      </button>
    </SidebarStyles>
  )
}

export default Sidebar
