import styled, { css } from 'styled-components'
import { COLORS } from 'utils'

const centerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Clickable = styled.div<{ center?: boolean; disabled?: boolean; link?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
  transition: opacity 0.2s;
  user-select: none;
  color: ${({ link }) => link && COLORS.LINK};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  
  &:hover {
    text-decoration: ${({ link }) => (link ? 'underline' : 'unset')};
    filter: brightness(1.2);
  }

  ${(props) => (props.center ? centerStyle : '')};

  transition: all 0.3s;
`

export default Clickable
