// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    display: block;
    content: '';
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 6px solid #ffce5f;
    border-left-color: rgba(255, 206, 95, 0.47);
    border-right-color: rgba(255, 206, 95, 0.47);
    animation: rotation 1s infinite linear;
  }

  &.small {
    &::after {
      width: 25px;
      height: 25px;
      border: 4px solid #ffce5f;
      border-left-color: rgba(255, 206, 95, 0.47);
      border-right-color: rgba(255, 206, 95, 0.47);
    }
  }
  
  &.extra-small {
    &::after {
      width: 18px;
      height: 18px;
      border: 3px solid #ffce5f;
      border-left-color: rgba(255, 206, 95, 0.47);
      border-right-color: rgba(255, 206, 95, 0.47);
    }
  }
`

const Loading: React.FC<{ size?: 'small' | 'extra-small'; className?: any }> = ({ size, className }) => (
  <StyledDiv className={classnames(size, className)} />
)

export default Loading
