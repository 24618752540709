import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// import Misc from 'utils/misc'
import { UIState, QAConfigItem } from '../types'

const initialState: UIState = {
  sideBarStatus: false,
  fiwaUsd: 0,
  qaList: [],
}

export const uiSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setSideBarStatus: (state, action: PayloadAction<boolean>) => {
      state.sideBarStatus = action.payload
    },

    setFiwaUsd: (state, action: PayloadAction<number>) => {
      state.fiwaUsd = action.payload
    },

    setQAList: (state, action: PayloadAction<QAConfigItem[]>) => {
      state.qaList = action.payload
    },
  },
})

// Actions
export const { setSideBarStatus, setFiwaUsd, setQAList } = uiSlice.actions

export default uiSlice.reducer
