import { PublicApi, requestHelper, MainApiMeta } from './endpoint'

export function randomMessage() {
  return PublicApi.get('/random-message')
}

export function sessionLogin(payload: {
  message: string
  publicAddress: string
  referralCode?: string
  signature: string
}) {
  return requestHelper(PublicApi.post('/user/authentication/session', payload))
}

export function web3AuthLogin(payload: {
  aggregateVerifier: string
  appKey: string
  publicAddress: string
  idToken: string
  verifierId: string
}) {
  return requestHelper(PublicApi.post('/user/authentication/web3-auth', payload))
}

export function firebaseLogin(payload: { referralCode?: string; token: string }) {
  return requestHelper(PublicApi.post('/user/authentication/firebase-login', payload))
}

export function linkFirebase(payload: { firebaseToken: string }) {
  return requestHelper(MainApiMeta.post('/user/authentication/link/firebase', payload))
}

export function linkMetamask(payload: { address: string; message: string; signature: string }) {
  return requestHelper(MainApiMeta.post('/user/authentication/link/metamask', payload))
}

export function LinkEmailForAccountMeta(payload: interfaceLinkEmailForAccountMeta) {
  return requestHelper(MainApiMeta.post('/user/authentication/emails/link', payload))
}

export function ReSendCodeVerifyEmail() {
  return requestHelper(MainApiMeta.post('/user/authentication/emails/verify/re-send', {}))
}

export function VerifyEmail(payload: interfaceVerifyEmail) {
  return requestHelper(MainApiMeta.post('/user/authentication/emails/verify', payload))
}

export function VerifyRevertEmail() {
  return requestHelper(MainApiMeta.post('/user/authentication/emails/verify/revert', {}))
}

export function ChangePasswordEmail(payload: interfaceChangePassword) {
  return requestHelper(MainApiMeta.put('/user/authentication/emails/password', payload))
}

export function GetProfile() {
  return requestHelper(MainApiMeta.get('/users/info'))
}

export function requestResetPassword(payload: interfaceresetPassword) {
  return requestHelper(MainApiMeta.post('/user/authentication/emails/password', payload))
}

export interface interfaceresetPassword {
  email: string
}

export interface interfaceLinkEmailForAccountMeta {
  email: string
  password: string
}

export interface interfaceUpdateNameforUser {
  userId: any
  userName: string
}

export interface interfaceChangePassword {
  currentPassword: string

  newPassword: string
}

export interface interfaceVerifyEmail {
  code: string
}

export interface interfaceReSendCodeVerifyEmail {
  email: string
}
export interface getLinkLoginMobileRequest {
  publicAddress: string
  signature: string
  message: string
}

export interface getLinkLoginMobileResponse {
  loginId?: number
  secureRandom?: string
}

export const getLinkLoginMobile = async (body: getLinkLoginMobileRequest) => {
  return PublicApi.post('/user/authentication/mobile-login', body)
}

export const checkAuthMethod = async (address: string) => {
  return requestHelper(PublicApi.get('/user/authentication/authentication-web-method', { address }))
}

export const getInfoMaintain = async () => {
  return requestHelper(MainApiMeta.get('/generals/maintain'))
}
