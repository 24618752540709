export const MENUS = {
  HOME: 'HOME',
  ABOUT: 'ABOUT',
  MERACIAN: 'MERACIAN',
  PROPERTY: 'PROPERTY',
  FEATURES: 'FEATURES',
  ROAD_MAP: 'ROADMAP',
  TEAM: 'TEAM',
}
export const TOKEN_MENU = {
  CLAIM_TOKEN: 'CLAIM TOKEN',
  BRIDGE_TOKEN: 'BRIDGE TOKEN',
  BRIDGE_NFT: 'BRIDGE NFT',
  MARKETPLACE: 'MARKETPLACE',
}
export const CHARACTER_SEX = {
  BOY: 'boy',
  GIRL: 'girl',
}

export const CHAR_BOY_COLORS = [
  {
    primary: '#FAD556',
    secondary: '#111111',
    beforeBg: 'linear-gradient(178.97deg, #f7931e 3.38%, #fbcd23 77.84%, #ffff27 141.41%);',
    afterBg: 'linear-gradient(178.97deg, #ffff27 3.38%, #fbcd23 77.84%, #f7931e 141.41%);',
  },
  {
    primary: '#DA3730',
    secondary: '#111111',
    beforeBg: 'linear-gradient(178.97deg, #f7931e 3.38%, #fbcd23 77.84%, #ffff27 141.41%);',
    afterBg: 'linear-gradient(178.97deg, #ffff27 3.38%, #fbcd23 77.84%, #f7931e 141.41%);',
  },
  {
    primary: '#1C3366',
    secondary: '#C816A7',
    beforeBg: 'linear-gradient(178.97deg, #f7931e 3.38%, #fbcd23 77.84%, #ffff27 141.41%);',
    afterBg: 'linear-gradient(178.97deg, #ffff27 3.38%, #fbcd23 77.84%, #f7931e 141.41%);',
  },
  {
    primary: '#3BC056',
    secondary: '#111111',
    beforeBg: 'linear-gradient(178.97deg, #f7931e 3.38%, #fbcd23 77.84%, #ffff27 141.41%);',
    afterBg: 'linear-gradient(178.97deg, #ffff27 3.38%, #fbcd23 77.84%, #f7931e 141.41%);',
  },
  {
    primary: '#DC097D',
    secondary: '#F1CD63',
    beforeBg: 'linear-gradient(178.97deg, #f7931e 3.38%, #fbcd23 77.84%, #ffff27 141.41%);',
    afterBg: 'linear-gradient(178.97deg, #ffff27 3.38%, #fbcd23 77.84%, #f7931e 141.41%);',
  },
]

export const CHAR_GIRL_COLORS = [
  {
    primary: '#D93971',
    secondary: '#111111',
    beforeBg: 'linear-gradient(178.85deg, #ba2b5e -30.44%, #da567d 57.13%, #f275b3 141.13%);',
    afterBg: 'linear-gradient(178.85deg, #f275b3 -30.44%, #da567d 57.13%, #ba2b5e 141.13%);',
  },
  {
    primary: '#33BD62',
    secondary: '#111111',
    beforeBg: 'linear-gradient(178.85deg, #ba2b5e -30.44%, #da567d 57.13%, #f275b3 141.13%);',
    afterBg: 'linear-gradient(178.85deg, #f275b3 -30.44%, #da567d 57.13%, #ba2b5e 141.13%);',
  },
  {
    primary: '#9F38EB',
    secondary: '#A3E8C8',
    beforeBg: 'linear-gradient(178.85deg, #ba2b5e -30.44%, #da567d 57.13%, #f275b3 141.13%);',
    afterBg: 'linear-gradient(178.85deg, #f275b3 -30.44%, #da567d 57.13%, #ba2b5e 141.13%);',
  },
  {
    primary: '#FD8552',
    secondary: '#1639BB',
    beforeBg: 'linear-gradient(178.85deg, #ba2b5e -30.44%, #da567d 57.13%, #f275b3 141.13%);',
    afterBg: 'linear-gradient(178.85deg, #f275b3 -30.44%, #da567d 57.13%, #ba2b5e 141.13%);',
  },
  {
    primary: '#81F9FE',
    secondary: '#D299FF',
    beforeBg: 'linear-gradient(178.85deg, #ba2b5e -30.44%, #da567d 57.13%, #f275b3 141.13%);',
    afterBg: 'linear-gradient(178.85deg, #f275b3 -30.44%, #da567d 57.13%, #ba2b5e 141.13%);',
  },
]
export const VIDEO_TRAILER = {
  linkVideo: 'https://youtube.com/embed/KAqGqLJRTMY',
  linkThumb: 'https://img.youtube.com/vi/JvgGRAVls0o/maxresdefault.jpg',
}

export const BACKERS_SP = [
  {
    group: 'tier 1',
    list: [
      { name: 'signum capital', logo: 'signum.png', height: 40 },
      { name: 'hyper chain capital', logo: 'hyperchain.png', height: 40 },
      { name: 'okx ventures', logo: 'OKX.png', height: 40 },
      { name: 'gate.io', logo: 'gate.io_-1.png', height: 40 },
      { name: 'GFS', logo: 'GFS-1.png', height: 40 },
      { name: 'BAP', logo: 'BAP-1.png', height: 40 },
      { name: 'NGC', logo: 'NGC.png', height: 40 },
      { name: 'kyber', logo: 'kyber-2.png', height: 40 },
      { name: 'tomochain', logo: 'tomochain.png', height: 40 },
      { name: 'launch-zone', logo: 'launch-zone.png', height: 40 },
      { name: 'coin98', logo: 'coin98.png', height: 36 },
      { name: 'EX network', logo: 'EX.png', height: 40 },
    ],
  },
  {
    group: 'tier 2',
    list: [
      { name: 'titan', logo: 'titan.png', height: 40 },
      { name: 'VB-venture', logo: 'VB-venture.png', height: 40 },
      { name: 'Astrov', logo: 'Astrov.png', height: 40 },
      { name: 'Everse', logo: 'Everse.png', height: 40 },
      { name: 'BSC station', logo: 'BSC.png', height: 40 },
      { name: 'Spark', logo: 'Spark.png', height: 40 },
      { name: 'BAS', logo: 'BAS-1.png', height: 40 },
      { name: 'TAG venture', logo: 'TAG-1.png', height: 32 },
      { name: 'M6', logo: 'M6-1.png', height: 40 },
      { name: 'G-ventures', logo: 'G-ventures-1.png', height: 32 },
      { name: 'megala', logo: 'megala.png', height: 32 },
      { name: 'infinity-capital', logo: 'infinity-capital.png', height: 32 },
    ],
  },
  {
    group: 'tier 3',
    list: [
      { name: 'coinervn', logo: 'coinervn.png', height: 32 },
      { name: '96-ventures', logo: '96-ventures.png', height: 38 },
      { name: 'BBG', logo: 'BBG.png', height: 36 },
      { name: 'Basic-capital', logo: 'Basic-capital.png', height: 38 },
      { name: 'x21', logo: 'x21.png', height: 40 },
      { name: 'KC-capital', logo: 'KC-capital.png', height: 32 },
      { name: 'lua-ventures', logo: 'lua-ventures.png', height: 20 },
      { name: 'FIM ventures', logo: 'FIM.png', height: 36 },
      { name: 'migoi', logo: 'migoi.png', height: 40 },
      { name: 'Daolaunch', logo: 'Daolaunch.png', height: 38 },
    ],
  },
]

export const BACKERS_PC = [
  {
    group: 'tier 1',
    list: [
      { name: 'signum capital', logo: 'signum.png' },
      { name: 'hyper chain capital', logo: 'hyperchain.png' },
      { name: 'okx ventures', logo: 'OKX.png' },
      { name: 'gate.io', logo: 'gate.io_-1.png' },
      { name: 'GFS', logo: 'GFS-1.png' },
      { name: 'BAP', logo: 'BAP-1.png' },
    ],
  },
  {
    group: 'tier 2',
    list: [
      { name: 'NGC', logo: 'NGC.png' },
      { name: 'kyber', logo: 'kyber-2.png' },
      { name: 'tomochain', logo: 'tomochain.png' },
      { name: 'launch-zone', logo: 'launch-zone.png' },
      { name: 'coin98', logo: 'coin98.png' },
      { name: 'EX network', logo: 'EX.png' },
    ],
  },
  {
    group: 'tier 3',
    list: [
      { name: 'titan', logo: 'titan.png' },
      { name: 'VB-venture', logo: 'VB-venture.png' },
      { name: 'Astrov', logo: 'Astrov.png' },
      { name: 'Everse', logo: 'Everse.png' },
      { name: 'BSC station', logo: 'BSC.png' },
      { name: 'Spark', logo: 'Spark.png' },
    ],
  },
  {
    group: 'tier 4',
    list: [
      { name: 'BAS', logo: 'BAS-1.png' },
      { name: 'TAG venture', logo: 'TAG-1.png' },
      { name: 'M6', logo: 'M6-1.png' },
      { name: 'G-ventures', logo: 'G-ventures-1.png' },
      { name: 'megala', logo: 'megala.png' },
      { name: 'infinity-capital', logo: 'infinity-capital.png' },
    ],
  },
  {
    group: 'tier 5',
    list: [
      { name: 'coinervn', logo: 'coinervn.png' },
      { name: '96-ventures', logo: '96-ventures.png' },
      { name: 'BBG', logo: 'BBG.png' },
      { name: 'Basic-capital', logo: 'Basic-capital.png' },
      { name: 'x21', logo: 'x21.png' },
      { name: 'KC-capital', logo: 'KC-capital.png' },
    ],
  },
  {
    group: 'tier 6',
    list: [
      { name: 'lua-ventures', logo: 'lua-ventures.png' },
      { name: 'FIM ventures', logo: 'FIM.png' },
      { name: 'migoi', logo: 'migoi.png' },
      { name: 'Daolaunch', logo: 'Daolaunch.png' },
      { name: '', logo: '' },
      { name: '', logo: '' },
    ],
  },
]

export const FOUNDING_TEAM = [
  {
    key: 1,
    name: 'Mark Dao',
    avata: 'mark-dao-avt.png',
    roles: [
      'Co-founder.',
      'Founder & CEO at BAP Group.',
      'Former Game System Engineer and PM at CyberAgent Japan.',
      '5+ years in crypto investment.',
    ],
  },
  {
    key: 2,
    name: 'Victor Truong',
    avata: 'victor-truong-avt.png',
    roles: [
      'Co-founder.',
      'FCCA, Certified Internal Auditor.',
      'CEO - BAP Ventures.',
      '10+ years in Business & Finance.',
      '4+ years in crypto investment. ',
    ],
  },
  {
    key: 3,
    name: 'Long Vuong',
    avata: 'long-vuong-avt.png',
    roles: ['Cofounding Advisor.', 'CEO & Founder at Tomochain.'],
  },
  {
    key: 4,
    name: 'Loi Luu',
    avata: 'loi-luu-avt.png',
    roles: ['Cofounding Advisor.', 'CEO & Co-founder at Kyber Network.'],
  },
]

export const FOUNDING_TEAM_TL = [
  [
    {
      key: 1,
      name: 'Mark Dao',
      avata: 'mark-dao-avt.png',
      roles: [
        'Co-founder.',
        'Founder & CEO at BAP Group.',
        'Former Game System Engineer and PM at CyberAgent Japan.',
        '5+ years in crypto investment.',
      ],
    },
    {
      key: 2,
      name: 'Victor Truong',
      avata: 'victor-truong-avt.png',
      roles: [
        'Co-founder.',
        'FCCA, Certified Internal Auditor.',
        'CEO - BAP Ventures.',
        '10+ years in Business & Finance.',
        '4+ years in crypto investment. ',
      ],
    },
  ],
  [
    {
      key: 3,
      name: 'Long Vuong',
      avata: 'long-vuong-avt.png',
      roles: ['Cofounding Advisor.', 'CEO & Founder at Tomochain.'],
    },
    {
      key: 4,
      name: 'Loi Luu',
      avata: 'loi-luu-avt.png',
      roles: ['Cofounding Advisor.', 'CEO & Co-founder at Kyber Network.'],
    },
  ],
  [
    {
      key: 5,
      name: 'Mark Dao',
      avata: 'mark-dao-avt.png',
      roles: [
        'Co-founder.',
        'Founder & CEO at BAP Group.',
        'Former Game System Engineer and PM at CyberAgent Japan.',
        '5+ years in crypto investment.',
      ],
    },
    {
      key: 6,
      name: 'Victor Truong',
      avata: 'victor-truong-avt.png',
      roles: [
        'Co-founder.',
        'FCCA, Certified Internal Auditor.',
        'CEO - BAP Ventures.',
        '10+ years in Business & Finance.',
        '4+ years in crypto investment. ',
      ],
    },
  ],
  [
    {
      key: 7,
      name: 'Long Vuong',
      avata: 'long-vuong-avt.png',
      roles: ['Cofounding Advisor.', 'CEO & Founder at Tomochain.'],
    },
    {
      key: 8,
      name: 'Loi Luu',
      avata: 'loi-luu-avt.png',
      roles: ['Cofounding Advisor.', 'CEO & Co-founder at Kyber Network.'],
    },
  ],
]

export const ACHIEVEMENTS = [
  {
    name: 'BEAT MASTER',
    image: 'beat-master.png',
    icon: 'achievement-icon.png',
    text: '#1 In 53 Countries',
    key: 'achievements-1',
  },
  {
    name: 'SCREW FACTORY',
    image: 'screw-factory.png',
    icon: 'achievement-icon.png',
    text: '#5 In 18 Countries',
    key: 'achievements-2',
  },
  {
    name: 'METAL HEROES',
    image: 'metal-heroes.png',
    icon: 'achievement-icon.png',
    text: 'Featured on Google Play',
    key: 'achievements-3',
  },
]

export const HOW_TO_PLAY_URL =
  'https://2336467834-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2Fir6Oac6LhZJFH6ANanUE%2Fuploads%2Fh9E8KD26alshiZ37ekEn%2FTutorial_The%20Mera.pdf?alt=media&token=7ab3478f-d795-4c87-be64-a15d74679f66'
