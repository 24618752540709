import tinycolor from 'tinycolor2'

export const darken = (color: string, value: number): string => tinycolor(color).darken(value).toString()
export const lighten = (color: string, value: number): string => tinycolor(color).lighten(value).toString()
export const alpha = (color: string, value: number): string => tinycolor(color).setAlpha(value).toRgbString()
export const isLight = (color: string): boolean => tinycolor(color).isLight()

/**
 * @Author: tanht
 * @Date: 2021-11-23 08:22:16
 * @Desc: colors palette
 * @Cal: contrast analyzer tested
 */
export const TEXT_PRIMARY = '#FFFFFF' // common text
export const TEXT_SECONDARY = '#f0f0f0' // common secondary text color
export const PRIMARY = '#FFCE5F' // primary color: buttons, titles
export const BLUE = '#0057E2' // primary2 color: buttons, active status,
export const ACTIVE = BLUE // active color: switch button, tab,
export const WHEAT = '#f5f5f5' // secondary color: buttons
export const WARNING = '#E0CF36' // warning color: text
export const BG_COMPONENTS = '#062350' // component large background color: modals, card, boxes
export const BG_FIELD = '#041228' // fields background color: inputs
export const SPECIAL = '#7E2FFF' // SPECIAL color: buttons, titles,
export const ERROR = '#D54242' // error color text, buttons
export const SUCCESS = '#1AEC48' // success color: text, typography, buttons
export const LINK = '#00F0FF' // link text
export const DISABLED = '#656565' // disabled: buttons, colors,...

export const UPCOMING = '#FFCE5F'
export const BORDER_COLOR = '#363c4e'
