import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
import { ConnectorNames } from '@pancakeswap/uikit'
import { ethers } from 'ethers'
import { CURRENCIES, TCURRENCY } from 'config/constants/currencies'
import BigNumber from 'bignumber.js'
import { calculateGasMargin } from 'utils'
import getNodeUrl from './getRpcUrl'
import { getBusdContract, getFiwaContract, getUsdtContract } from './contractHelpers'
import { getECoinsAddress, getFiwaFarmAddress } from './addressHelpers'

const POLLING_INTERVAL = 12000
const rpcUrl = getNodeUrl()
const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)

const injected = new InjectedConnector({ supportedChainIds: [chainId] })

const walletconnect = new WalletConnectConnector({
  rpc: { [chainId]: rpcUrl },
  bridge: 'https://pancakeswap.bridge.walletconnect.org/',
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
  qrcodeModalOptions: {
    mobileLinks: ['metamask', 'trust'],
  },
})

const bscConnector = new BscConnector({ supportedChainIds: [chainId] })

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.BSC]: bscConnector,
}

export const getLibrary = (provider): ethers.providers.Web3Provider => {
  const library = new ethers.providers.Web3Provider(provider)
  library.pollingInterval = POLLING_INTERVAL
  return library
}

/**
 * BSC Wallet requires a different sign method
 * @see https://docs.binance.org/smart-chain/wallet/wallet_api.html#binancechainbnbsignaddress-string-message-string-promisepublickey-string-signature-string
 */
export const signMessage = async (provider: any, account: string, message: string): Promise<string> => {
  // if (window.BinanceChain) {
  if (window.ethereum) {
    // const { signature } = await window.BinanceChain.bnbSign(account, message)
    const signature: void = await window.ethereum.request({ method: 'personal_sign', params: [message, account] })
    return String(signature)
  }

  /**
   * Wallet Connect does not sign the message correctly unless you use their method
   * @see https://github.com/WalletConnect/walletconnect-monorepo/issues/462
   */
  if (provider.provider?.wc) {
    const wcMessage = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message))
    const signature = await provider.provider?.wc.signPersonalMessage([wcMessage, account])
    return signature
  }

  return provider.getSigner(account).signMessage(message)
}

export enum ACTIONS_TYPE {
  BUY_E_FIWA,
  STAKING,
}

export const approveCurrency = async (
  publicAddress: string,
  signer: ethers.Signer,
  currency: TCURRENCY,
  price: number,
  actionType: ACTIONS_TYPE,
) => {
  let contract
  switch (currency) {
    case CURRENCIES.USDT:
      contract = getUsdtContract(signer)
      break
    case CURRENCIES.BUSD:
      contract = getBusdContract(signer)
      break
    case CURRENCIES.FIWA:
      contract = getFiwaContract(signer)
      break
    default:
      break
  }

  let exchangeContractAddress
  if (actionType === ACTIONS_TYPE.BUY_E_FIWA) {
    exchangeContractAddress = getECoinsAddress()
  } else if (actionType === ACTIONS_TYPE.STAKING) {
    exchangeContractAddress = getFiwaFarmAddress()
  }

  if (contract && exchangeContractAddress) {
    const priceEther = new BigNumber(price).multipliedBy(10 ** 18)
    const allowance = await contract.allowance(publicAddress, exchangeContractAddress)

    if (priceEther.isGreaterThan(new BigNumber(allowance.toString()))) {
      const estimate = contract.estimateGas.approve
      const method = contract.approve
      const args = [exchangeContractAddress, priceEther.toFixed(0)]

      const estimatedGasLimit = await estimate(...args, {})
      const transactionResponse = await method(...args, {
        gasLimit: calculateGasMargin(estimatedGasLimit),
      })

      await transactionResponse.wait()
    }
  } else {
    throw new Error('Some thing went wrong')
  }
}
