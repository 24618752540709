export default {
  fiwaFarm: {
    97: '0xA267B797A2f791A52f5284C0F95088c6f9A15A17',
    56: '0xF9a822fdC99376746cfb704983fDb1E7Be6B1a27',
  },
  nftFactory: {
    97: '0x6883C84c99A9424D06c64fc03dc60b5003AbA4cE',
    56: '0xD21F97E08599560C721D668962Fba875684b03C4',
  },
  nftBodyPart: {
    97: '0x32f9039ce3Cd16913B677fFC9e94cfa5f8849217',
    56: '0x9B4dC1327c8b8A5fA3989b48699747Ed1618EEF2',
  },
  nftManager: {
    97: '0x5fd2F68591edEA97Ee904dad3a7fd3916263E8A8',
    56: '0xea35B30A42Fc858E9Ee52C7BeEa129a1100aFcDC',
  },
  exchange: {
    97: '0xDe12436B2FB868c42BFe2F0F8a46c145c8Ca57e6',
    56: '0xfdCa3499E8b08052F41b5F63BCfE918F98Bc8c42',
  },
  exchangeV2: {
    97: '0x831aE5296A315AD2005ce4840E4F414F1D79B9a6',
    56: '0x6D945dCa138ACa2F73bA8244A599FD84302636B0',
  },
  gemExchange: {
    97: '0xB58D5cf807Fcc84b79413d00598a6c0244cB6051',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  masterChef: {
    97: '0x364c8C225aE6c6AC62F5a6E36e58df8ED5cB82EE',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  cwigShop: {
    97: '0x3230a0C0B7FD57F6a5AdA6858DbdEC80cd7e8a97',
    56: '0xcd5383FAD43aAbEFA54710c77431dFA7BEd95E18',
  },
  eCoins: {
    97: '0x3230a0C0B7FD57F6a5AdA6858DbdEC80cd7e8a97',
    56: '0xcd5383FAD43aAbEFA54710c77431dFA7BEd95E18',
  },
  nftStakingPool: {
    97: '0x8593a9E98a5bE4fc864D0A666212943FD9efe0E3',
    56: '0xB4a8F1255C3AeB6bEB3C1c4273bDdBD8A9a0C6a8',
  },
  cwigStakingPoolV1: {
    97: '0xC009Db1643792F4f1c04Ba6570Fa55D72C12c77D',
    56: '0xc6c0C0c79de7324c5D092a266547eF27F9AE512D',
  },
  fiwaStakingPoolV1: {
    97: '0x207C5786c25794ab0B8d873B809B3c1f91dc5396',
    56: '0x308d6B943b439f33531353DeEc63C3e5E71B5662',
  },
  cwigStakingPoolV2: {
    97: '0xC009Db1643792F4f1c04Ba6570Fa55D72C12c77D',
    56: '0x51ae43e569144582e15361c342c51875A80F0608',
  },
  fiwaStakingPoolV2: {
    97: '0x207C5786c25794ab0B8d873B809B3c1f91dc5396',
    56: '0x302a0d54dD3CE8a31FD156cA6a5EA0e0cCbccc2E',
  },
  fiwaNftStakingPool: {
    97: '0x03190494D0dCDacaE1b0e13E96FE53E6459A013D',
    56: '0xF24f6A75fc3902D75494991f1c62531651bb4696',
  },
  twoDollarsTicket: {
    97: '0x9226745194eEb97bd3F7a3A55E6ed02A3506902F',
    56: '0x9f13aB20416f43cAB54f45671893EeB2281c3ac2',
  },
  worldBoss: {
    97: '0x3ebC89a626c0523197E4BE29EC2CeB454D99Ad69',
    56: '0x298856ab2213D77FC9AD46f62E9d0508f728f631',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lotteryV2: {
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    56: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
  },
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetition: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  cakeVault: {
    56: '0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC',
    97: '',
  },
  predictions: {
    56: '0x516ffd7D1e0Ca40b1879935B2De87cb20Fc1124b',
    97: '0x4f3140C74789F1D809420343ea83BcE52B7bbAA5',
  },
  chainlinkOracle: {
    56: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    97: '',
  },
  bunnySpecialCakeVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
  },
  binanceNFT: {
    56: '0xe263Fd424347464A41FD761aC88E23edc9b0156a',
    97: '0x2dDCeA10DaA798950fb8239430cd196B5098f61F',
  },
  router: {
    56: '0xB1C24d16d9E361D96990D5580C524266Ec9fde06',
    97: '0xB1C24d16d9E361D96990D5580C524266Ec9fde06',
  },
  lpFarm: {
    56: '0x8226C355E1d602b6606cfc41Fb7E129e8b7DdeAB',
    97: '0xD44f0586ea378ec58b2e7a1e1b94028012cC53D3',
  },
  iwo: {
    56: '0xe9DFB794A983e7f374A4096B5De8E68998B66888',
    97: '0xB571c1dFfaED0f04d0cca6b96eb76C4BeB18F7F2',
  },
  landBox: {
    56: '',
    97: '0xADa3B7EFa01DC49C0bF03c317477dc585d65EF21',
  },
}
