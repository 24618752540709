import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { useWeb3Auth } from 'contexts/Web3AuthContext'
import { useWalletModal } from 'components/Menu/WalletModal'
import useAuth, { useLogin } from 'hooks/useAuth'
import { HOW_TO_PLAY_URL } from 'config/constants/mera-home'
import Download from 'components/Menu/Download'
import MsidebarRight from 'components/Menu/MsidebarRight'
import { navbar } from 'config/constants/menu'
import { useMaintainProvider } from 'contexts/MaintainContext'
import ConnectButton from 'components/ConnectButton'
import MsidebarLeft from 'components/Menu/MsidebarLeft'
import { ConfigAccountContext } from 'contexts/ConfigAccount/ConfigAccountContext'
import { ActionCenterStyles, ContentStyles, HeaderStyles, MainDivStyles } from './styled'

const GameLayout = ({ children }) => {
  const { initing, maintain } = useMaintainProvider()
  const navigate = useNavigate()
  const { account } = useWeb3React()
  const { account: accountWeb3Auth } = useWeb3Auth()
  const [showMenuLeft, setShowMenuLeft] = useState(false)
  const [showMenuRight, setShowMenuRight] = useState(false)
  const { login, logout } = useAuth()
  const { logined, loginType } = useLogin()

  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(login, logout, account || accountWeb3Auth)

  useEffect(() => {
    document.getElementsByTagName('html')[0].style.backgroundImage = `url('/mera/bgapp.png')`
    document.getElementsByTagName('body')[0].style.backgroundImage = `url('/mera/bgapp.png')`

    if (!initing) {
      if (maintain) navigate('/maintain', { replace: true })
    }
  }, [navigate, initing, maintain])

  const handleShowSideBarMobile = (typeShow: string) => {
    if (typeShow === 'left') {
      setShowMenuLeft(!showMenuLeft)
      setShowMenuRight(false)
    } else {
      setShowMenuRight(!showMenuRight)
      setShowMenuLeft(false)
    }
  }

  return (
    <ConfigAccountContext>
      <MainDivStyles>
        <HeaderStyles>
          <div className="group__header__menu">
            <button
              type="button"
              style={{ background: 'none', outline: 'none', border: 'none' }}
              className="icon__menu__mobile"
              onMouseDown={() => handleShowSideBarMobile('left')}
            >
              <img src="/mera/ICON_MENU.svg" alt="menu_icon" style={{ cursor: 'pointer' }} />
            </button>

            <NavLink
              className="logo"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              to="/"
            >
              <img src="mera/logo.png" alt="logo" style={{ objectFit: 'cover' }} />
            </NavLink>
          </div>

          <nav className="navbar-list">
            <ul className="navbar-list__item" style={{ listStyle: 'none' }}>
              {navbar.map((item) => (
                <li className="navbar-list__item__link" key={item.title}>
                  <NavLink
                    style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                    className={({ isActive }) => {
                      return isActive ? 'active' : 'inactive'
                    }}
                    to={item.pathName}
                  >
                    {item.title}
                  </NavLink>
                </li>
              ))}
            </ul>
            <ActionCenterStyles>
              <a className="how-to-play" href={HOW_TO_PLAY_URL} target="_blank" rel="noopener noreferrer">
                HOW TO PLAY?
              </a>
              <Download />

              {(account || accountWeb3Auth) && logined ? (
                <>
                  <NavLink to="/account" style={{ cursor: 'pointer' }} className="user__header__icon">
                    <img src="/mera/USER_HEADER.svg" alt="" />
                  </NavLink>
                  <button
                    className="user__header__icon__mobile"
                    type="button"
                    onClick={() => handleShowSideBarMobile('right')}
                    style={{ backgroundColor: 'unset', border: 'none', outline: 'none', cursor: 'pointer' }}
                  >
                    <img src="/mera/USER_HEADER.svg" alt="" />
                  </button>
                  <ConnectButton
                    onClick={onPresentAccountModal}
                    account={account || accountWeb3Auth}
                    logined={logined}
                    loginType={loginType}
                  />
                </>
              ) : (
                <ConnectButton onClick={onPresentConnectModal} />
              )}
            </ActionCenterStyles>
          </nav>
        </HeaderStyles>

        <ContentStyles> {children}</ContentStyles>
      </MainDivStyles>
      <MsidebarRight open={showMenuRight} handleClose={() => setShowMenuRight(!showMenuRight)} />
      <MsidebarLeft open={showMenuLeft} handleClose={() => setShowMenuLeft(!showMenuLeft)} />
    </ConfigAccountContext>
  )
}
export default GameLayout
