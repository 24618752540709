import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useLogin } from 'hooks/useAuth'
import { SidebarMobileRightStyles } from 'components/Layout/AccountLayout/styled'
import { sidebar } from 'config/constants/menu'
import Balance from 'components/Layout/AccountLayout/Balance'
import { useWeb3React } from '@web3-react/core'

interface Props {
  open: boolean
  handleClose: () => void
}
const MsidebarRight = ({ open, handleClose }: Props) => {
  const { account } = useWeb3React()
  const { logout: callLogout } = useLogin()
  const navigate = useNavigate()
  const handleLogOut = () => {
    callLogout()
    navigate('/')
  }
  return (
    <SidebarMobileRightStyles showMenuRight={open}>
      <div
        className="sidebar_right__overlay"
        tabIndex={0}
        aria-label="hide sidebar"
        onClick={handleClose}
        onKeyDown={handleClose}
        role="button"
      />
      <ul className="sidebar-list__mobile right" style={{ listStyle: 'none' }}>
        {sidebar.map((item) => (
          <li className="sidebar-list__mobile__item" key={item.key}>
            <NavLink
              className={({ isActive }: any) => (isActive ? 'active' : 'inactive')}
              style={{ position: 'relative' }}
              to={item.pathName}
            >
              {item.title}
              <img
                style={{ rotate: '180deg', position: 'absolute', right: '-25px' }}
                src="/mera/arrow_blue.svg"
                alt=""
                className="icon_arrow_blue"
              />
            </NavLink>
          </li>
        ))}
        {account && <Balance />}

        <button type="button" onClick={handleLogOut} className="btn_logout_big">
          Log out
          <img src="/mera/icon_logout.svg" alt="icon" />
        </button>
      </ul>
    </SidebarMobileRightStyles>
  )
}

export default React.memo(MsidebarRight)
