import styled from 'styled-components'

export const InputStyled = styled.div`
  .show__password {
    position: absolute;
    width: 15px;
    height: 15px;
    color: #fff;
    right: 10px;
    cursor: pointer;
    top: 14px;
    transition: all 0.2s ease-in-out;
  }
  border-radius: 4px;
  padding: 12px 10px 5px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  -moz-box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  & > label {
    font-family: 'Montserrat';
    font-style: normal;
    display: block;
    font-weight: 400;

    font-size: 12px;
    line-height: 18px;
    margin-bottom: 12px;
    color: white;
  }
  & > input {
    width: 100%;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    color: white;
    font-size: 16px;
    line-height: 18px;
    outline: none;
    background: transparent;
  }
  label::before {
    content: '';

    height: 100%;

    border-left: 1px solid #00f0ff;
    margin-right: 5px;
    width: 3px;
  }
`
export const ButtonStyled = styled.button`
  background: #0057e2;

  border-radius: 4px;

  padding: 10px;
  text-align: center;
  width: 100%;
  height: 45px;
  font-family: 'UTM Bebas';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border: none !important;
  outline: none;
  color: white;
  cursor: pointer;
  @media only screen and (max-width: 416px) {
    height: 40px;
    font-size: 14px;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
export const ContainerModalStyles = styled.div`
  width: 100%;
  height: 100vh;

  position: fixed;
  inset: 0;

  .box__modal__move {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
`
