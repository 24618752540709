import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
// import Init from './Init'
import Providers from './Providers'
import 'services/firebase'
import 'particles.js'

// function Updaters() {
//   return (
//     <>
//       <ListsUpdater />
//       <ApplicationUpdater />
//       <TransactionUpdater />
//       <MulticallUpdater />
//     </>
//   )
// }

// function Blocklist({ children }: { children: ReactNode }) {
//   const { account } = useActiveWeb3React()
//   const blocked: boolean = useMemo(() => Boolean(account && BLOCKED_ADDRESSES.indexOf(account) !== -1), [account])
//   if (blocked) {
//     return <div>Blocked address</div>
//   }
//   return <>{children}</>
// }

const container = document.getElementById('root')
const root = createRoot(container)
// ReactDOM.render(
root.render(
  <React.StrictMode>
    {/* <Blocklist> */}
    <Providers>
      <BrowserRouter>
        {/* <Updaters /> */}
        <App />
      </BrowserRouter>
    </Providers>
    {/* </Blocklist> */}
  </React.StrictMode>,
  // document.getElementById('root'),
)
