import React from 'react'
import styled from 'styled-components'
import { Modal, ModalProps } from '@pancakeswap/uikit'
import Media from 'utils/media'
import Typography from 'components/Typography'

/**
 * Over write from: https://pancakeswap.github.io/pancake-uikit/?path=/docs/widgets-modal--with-back-button
 */
const ModalAccount: React.FC<ModalProps> = (props) => {
  const { children, title, ...rest } = props

  return (
    <StyledModalAccount
      {...rest}
      title=""
      bodyPadding="26px"

      //
    >
      {title !== 'YOUR WALLET' ? (
        <Typography center size="enormous" className="title" changaFont>
          {title}
        </Typography>
      ) : (
        <div className="title__logout">Your wallet</div>
      )}

      {children}
    </StyledModalAccount>
  )
}

const StyledModalAccount = styled(Modal)<any>`
  .title__logout {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    /* or 112% */
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    /* White */

    color: #ffffff;
  }
  border-radius: 8px;
  width: 520px;
  position: relative;
  /* background-color: #062350; */
  background-clip: padding-box;
  /* border: 0;
  border-radius: 8px; */
  /* box-shadow: 0 3px 6px -4px rgb(0 0 0 / 48%), 0 6px 16px 0 rgb(0 0 0 / 32%), 0 9px 28px 8px rgb(0 0 0 / 20%); */
  pointer-events: auto;
  background: linear-gradient(121.2deg, rgba(157, 0, 183, 37.35) -12.69%, rgba(0, 13, 135, 7.65) 100%);
  /* White */

  border: 2px solid #ffffff;
  box-shadow: 6px 5px 0px #3c0883;
  border-radius: 10px;
  word-break: break-word;

  ${Media.lessThan(Media.SIZE.SM)} {
    max-width: 90%;
    width: 90%;
    margin: 25px;
  }

  .title {
    color: white;
    margin-bottom: 36px;
  }

  & > div {
    position: relative;
    &:nth-child(1) {
      border-bottom: none;
      padding: 0;
    }
    & > button {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      svg {
        fill: white !important;
      }
    }
  }
`

export default ModalAccount
