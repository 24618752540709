import { createSlice } from '@reduxjs/toolkit'
import { HomeState } from 'state/types'

const initialState: HomeState = {
  currentInhabitantId: 1,
  isMC: true,
  currentPetId: 1,
  isAndrill: true,
  currentPropertyId: 1,
}

export const homeSlice = createSlice({
  name: 'Home',
  initialState,
  reducers: {
    setInhabitant: (state, action) => {
      state.currentInhabitantId = action.payload.id as number
      state.isMC = action.payload.isMC
    },
    setPet: (state, action) => {
      state.currentPetId = action.payload.id as number
      state.isAndrill = action.payload.isAndrill
    },
    setProperty: (state, action) => {
      state.currentPropertyId = action.payload.id as number
    },
  },
})

export const { setInhabitant, setPet, setProperty } = homeSlice.actions

export default homeSlice.reducer
