import React, { useEffect, useState, useCallback, useRef } from 'react'
import styled from 'styled-components'
import lodash from 'lodash'
import ModalLogin from 'components/Modal/ModalLogin'
import { useWeb3React } from '@web3-react/core'
import { useLogin } from 'hooks/useAuth'
import { randomMessage, sessionLogin, GetProfile } from 'api/auth'
import Clickable from 'components/Clickable'
import Typography from 'components/Typography'
import useToast from 'hooks/useToast'
import { signMessage } from 'utils/web3React'
import Storage from 'utils/storage'
import Request from 'utils/request'
import Media from 'utils/media'
import { WALLET_ADAPTERS } from '@web3auth/base'
import { useWeb3Auth } from 'contexts/Web3AuthContext'
import WalletCard from './WalletCard'
import config, { web3AuthConnectors } from './config'
import { Login, ConnectorNames } from './types'

interface Props {
  login: Login
  onDismiss?: () => void
  referralCode?: string
  logout?: () => void
  onBack?: () => void
  hideCloseButton?: boolean
}

const StyledDiv = styled.div`
  .wallet-box {
    display: flex;
    gap: 20px;

    .button {
      flex: 1;
      background: #0057e2;
      border-radius: 6px;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        margin-bottom: 10px;
      }

      .bottom-text {
        margin-top: 15px;
        color: #ffce5f;
        font-style: italic;
        font-weight: bold;
      }
    }
  }

  .link-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    .link-title {
      margin-bottom: 10px;
      line-height: 22px;
    }

    .caution-text {
      margin: 4px 0 10px;
      color: #07ffff;
      line-height: 22px;
    }

    .skip-button {
      padding: 0 30px;
      margin-top: 20px;
    }

    .link-form {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      width: 100%;

      .link-button {
        &:last-child {
          margin-bottom: 0;
        }
        height: 68px;
        width: 100%;
        background: rgba(4, 18, 40, 0.35);
        border-radius: 4px;
        margin-bottom: 14px;
        display: flex;
        position: relative;
        align-items: center;
        ${Media.lessThan(Media.SIZE.MD)} {
          height: 44px;
        }
        .align-middle-logo {
          display: flex;
          align-items: center;
          position: absolute;
          left: 33%;
          ${Media.lessThan(Media.SIZE.MD)} {
            left: 30%;
          }
          img {
            margin-right: 16px;
            ${Media.lessThan(Media.SIZE.MD)} {
              margin-right: 12px;
            }
          }

          .typography {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 18px;
            letter-spacing: 0.02em;
            color: #ffffff;
            ${Media.lessThan(Media.SIZE.MD)} {
              font-weight: 600;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .sign-in-with {
    height: 39px;
    width: 100%;
    overflow: hidden !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    ${Media.lessThan(Media.SIZE.MD)} {
      height: 22px;
    }
    & > span {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      position: relative;
      letter-spacing: 0.02em;
      color: #ffffff;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 150px;
        height: 0.5px;
        background: #ffffff;
        top: 50%;
        left: -185%;
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 150px;
        height: 0.5px;
        background: #ffffff;
        top: 50%;
        right: -185%;
      }
    }
  }
`

const ConnectModal: React.FC<Props> = ({
  login,
  logout,
  hideCloseButton,
  onBack,
  onDismiss = () => null,
  referralCode,
}) => {
  const { login: loginWeb3Auth } = useWeb3Auth()
  const inited = useRef(false)
  const { library, account } = useWeb3React()
  const { setLogined, setLoginType } = useLogin()
  const { toastError } = useToast()
  const [forceRefreshCount, setForceRefreshCount] = useState<number>(0)

  const onNormalLogin = useCallback(
    async (isCloseOnSuccess) => {
      try {
        const messageResult = await randomMessage()
        const signature = await signMessage(library, account, messageResult.data.message)

        const loginResult = await sessionLogin({
          publicAddress: account,
          referralCode,
          signature,
          message: messageResult.data.message,
        })
        if (!loginResult.success) throw loginResult.data

        Storage.set('LOGIN_RESULT', { ...loginResult.data, account })
        Storage.set('ACCESS_TOKEN', loginResult.data.accessToken)
        Storage.set('PUBLIC_ADDRESS', account)
        Request.setAccessToken(loginResult.data.accessToken)

        setLogined(true)

        if (isCloseOnSuccess) onDismiss()
      } catch (e: any) {
        if (![4001, -32000].includes(e?.code)) {
          toastError(e?.message)
        }
        throw e
      }
    },
    [setLogined, account, library, onDismiss, referralCode, toastError],
  )

  const onFetchData = useCallback(async () => {
    try {
      await onNormalLogin(null)
      const initialData = await GetProfile()
      let notShowLinkAccounts = Storage.get('DO_NOT_SHOW_LINK_ACCOUNTS')
      notShowLinkAccounts = lodash.isArray(notShowLinkAccounts) ? notShowLinkAccounts : []
      const notShowLinkAccount = notShowLinkAccounts.find(
        (item) => item.address?.toLocaleLowerCase() === account?.toLocaleLowerCase(),
      )

      if (!initialData.data.firebaseUid && !notShowLinkAccount?.isNotShowLink) {
        onDismiss()
      } else {
        onDismiss()
      }
    } catch (e) {
      logout()
      console.error(e)
    }
  }, [account, logout, onDismiss, onNormalLogin])

  useEffect(() => {
    if (account && inited.current) onFetchData()
    else inited.current = true
  }, [forceRefreshCount, onNormalLogin, onDismiss, setLogined, logout, account, onFetchData])

  const onConnectWallet = useCallback(
    (walletConfig) => {
      login(walletConfig.connectorId)
      setLoginType({ title: walletConfig?.title, icon: walletConfig?.whiteIcon })
      Storage.set('WALLET', walletConfig.connectorId === ConnectorNames.Injected ? 1 : 2)
      Storage.set('connectorId', walletConfig.connectorId)

      if (account) {
        setForceRefreshCount(forceRefreshCount + 1)
      }
    },
    [login, setLoginType, account, forceRefreshCount],
  )

  const onConnectWeb3Auth = useCallback(
    async (connector) => {
      try {
        await loginWeb3Auth(WALLET_ADAPTERS.OPENLOGIN, connector?.loginProvider)
        Storage.set('WALLET', 3)
        Storage.set('connectorId', connector?.loginProvider)
        if (onDismiss) onDismiss()
      } catch (error: any) {
        // console.log('catch: ', { error })
        toastError('Something went wrong')
      }
    },
    [loginWeb3Auth, onDismiss, toastError],
  )

  return (
    <ModalLogin
      hideCloseButton={hideCloseButton}
      title="Register / Sign in"
      onBack={onBack}
      onDismiss={onDismiss}
      bodyPadding="0px"
    >
      <StyledDiv>
        <div className="link-box">
          <div className="link-form">
            {web3AuthConnectors.map((connector: any) => (
              <Clickable
                key={connector?.loginProvider}
                className="link-button"
                onClick={() => onConnectWeb3Auth(connector)}
              >
                <div className="align-middle-logo">
                  <img src={connector?.icon} alt={connector?.title} />
                  <Typography>{connector?.title}</Typography>
                </div>
              </Clickable>
            ))}
          </div>
        </div>
        <div className="sign-in-with">
          <span>or sign in with</span>
        </div>
        <div className="wallet-box">
          {config.map(
            (walletConfig: any) =>
              walletConfig?.show && (
                <WalletCard
                  key={walletConfig?.title}
                  walletConfig={walletConfig}
                  onClick={() => onConnectWallet(walletConfig)}
                />
              ),
          )}
        </div>
      </StyledDiv>
    </ModalLogin>
  )
}

export default ConnectModal
