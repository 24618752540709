import React, { useState, useEffect, createContext, useContext } from 'react'
import { GetProfile } from 'api/auth'
import useAuth, { useLogin } from 'hooks/useAuth'
import Request from 'utils/request'
import Storage from 'utils/storage'
import connectors from 'components/Menu/WalletModal/config'
import { ToastsContext } from 'contexts/ToastsContext'

const initState = {}
const ConfigContext = createContext<any>(initState)

export const ConfigAccountContext = ({ children }) => {
  const { toastError } = useContext(ToastsContext)
  const [configured, setConfigured] = useState<any>(initState)
  const { setLogined, setLoginType, setLoading, logined } = useLogin()
  const { login } = useAuth()

  async function fetchUser() {
    setConfigured(initState)
    try {
      const { success, data } = await GetProfile()
      if (success) {
        setConfigured({ ...data, name: data.name || data.userName || data.publicAddress })
      }
    } catch (error: any) {
      toastError(error?.message || 'Something went wrong')
    }
  }

  useEffect(() => {
    if (logined) {
      fetchUser()
    } else {
      setConfigured(initState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logined])

  useEffect(() => {
    const token = Storage.get('ACCESS_TOKEN')
    let connectorId = Storage.get('WALLET')
    connectorId = +connectorId
    if (token && [1, 2].includes(connectorId)) {
      Request.setAccessToken(token)

      try {
        const connector = connectors[connectorId - 1]
        login(connector.connectorId)

        setLogined(true)
        setLoginType({ title: connector?.title, icon: connector?.whiteIcon })
      } catch (e) {
        console.error(e)
      }
    }
    setLoading(false)
  }, [login, setLoading, setLogined, setLoginType])

  // useEffect(() => {
  //   if (account) {
  //     const publicAddress = Storage.get('PUBLIC_ADDRESS')

  //     if (account !== publicAddress) {
  //       setLogined(false)
  //     }
  //   }
  // }, [setLogined, account])

  const store = {
    configured,
    setConfigured,
    fetchUser,
  }
  return <ConfigContext.Provider value={store}>{children}</ConfigContext.Provider>
}

export const useConfigAccountContext = () => {
  const context = React.useContext<any>(ConfigContext)
  if (typeof context === 'undefined') {
    throw new Error('useConfigAccountContext must be used within a ConfigAccountContext')
  }
  return context
}
