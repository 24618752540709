import isFunction from 'lodash/isFunction'
import hasIn from 'lodash/hasIn'

import Request from 'utils/request'
import Storage from 'utils/storage'

const { REACT_APP_META_API_URL } = process.env
// Edit the suffix base on the version of your server API
const endpoint = `${REACT_APP_META_API_URL}/api/v1`

const MainApiMeta = Request.create({
  endpoint,
  handleToken: true,
})

// Endpoint that using to access server APIs
const PublicApi = Request.create({
  endpoint,
})

// Endpoint that using to access external APIs
const ExternalApi = Request.create({
  endpoint: '',
})

// Endpoint that using to download file which will response a Blob object
const BlobApi = Request.create({
  endpoint,
  handleToken: true,
  handleBlob: true,
})

const requestHelper = async (callbackPromise) => {
  let success = false
  let data

  try {
    const result = await callbackPromise

    // eslint-disable-next-line prefer-destructuring
    data = result.data
    success = true
  } catch (e: any) {
    let error = e
    if (!!error && hasIn(error, 'status') && isFunction(error.json)) {
      try {
        error = await e.json()
      } catch (childError) {
        console.error(444, childError)
      }
    }

    if (['ERROR_JWT_TOKEN_EXPIRED', 'ERROR_JWT_INVALID_TOKEN', 'PERMISSION_DENIED'].includes(error.error)) {
      Storage.remove('ACCESS_TOKEN')
      // eslint-disable-next-line no-restricted-globals
      location.reload()
    }

    data = error
  }

  return {
    success,
    data,
  }
}

export { MainApiMeta, ExternalApi, BlobApi, requestHelper, PublicApi }
