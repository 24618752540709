import React from 'react'
import styled from 'styled-components'

function ConnectButton({
  onClick,
  account,
  logined,
  loginType,
}: {
  onClick: () => void
  account?: string
  logined?: boolean
  loginType?: { title: string; icon: string }
}) {
  const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : null
  return (
    <ConnnectButtonStyled onClick={onClick}>
      {account && logined ? (
        <div className="wallet">
          <img src={loginType?.icon} alt={loginType?.title} width={15} height={15} />
          <span>{accountEllipsis}</span>
        </div>
      ) : (
        <span>LOGIN</span>
      )}
    </ConnnectButtonStyled>
  )
}

export default ConnectButton

const ConnnectButtonStyled = styled.button`
  background-image: url('/images/Button_connect.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: block;
  padding: 0;
  cursor: pointer;
  min-width: 120px;
  height: 46px;
  background-color: transparent;
  border: none;
  outline: none;
  padding-bottom: 4px;
  z-index: 10;
  transition: all 0.2s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
  @media only screen and (max-width: 768px) {
    min-width: 90px;
    height: 34px;
  }
  .wallet,
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-family: 'UTM Bebas';
    color: white;
    font-size: 16px;
    white-space: nowrap;
    @media only screen and (max-width: 768px) {
      font-size: 14px;
      top: 57%;
    }
  }
`
