// @ts-nocheck
import React from 'react'
import { Text } from '@pancakeswap/uikit'
import styled from 'styled-components'
import Media from 'utils/media'
import CircleLoader from 'components/Loader/CircleLoader'

interface Props {
  loading?: boolean
  onClick: () => void
  walletConfig: any
}

const ButtonStyled = styled.div`
  width: 210px;
  height: 44px;
  cursor: pointer;
  border: 1px solid #ffffff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${Media.lessThan(Media.SIZE.MD)} {
    height: 32px;
  }
  svg {
    height: 20px;
    width: 20px;
  }
`
const ContentButton = styled.div`
  .btn--icontext {
    display: flex;
    align-items: center;
    justify-content: center;
    &__title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      margin-left: 8px;
      ${Media.lessThan(Media.SIZE.MD)} {
        font-size: 11px;
      }
    }
  }
  &:hover {
    filter: brightness(1.2);
  }
`

const WalletCard: React.FC<Props> = ({ loading, onClick, walletConfig }) => {
  const { title, icon: Icon } = walletConfig

  return (
    <ButtonStyled onClick={onClick} id={`wallet-connect-${title.toLocaleLowerCase()}`}>
      <ContentButton>
        {loading ? (
          <CircleLoader />
        ) : (
          <div className="btn--icontext">
            <Icon />
            <Text className="btn--icontext__title">{title}</Text>
          </div>
        )}
      </ContentButton>
    </ButtonStyled>
  )
}

export default WalletCard
