import React, { lazy, useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ResetCSS } from '@pancakeswap/uikit'
import ScrollToTop from 'components/ScrollToTop'
import { DefaultLayout } from 'components/Layout/AccountLayout'
import GlobalStyle from './style/Global'
import PageLoader from './components/Loader/PageLoader'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'

const MeraHome = lazy(() => import('./views/MeraHome'))
const Playing = lazy(() => import('./views/Playing'))
const ConfigAccount = lazy(() => import('views/ConfigAccount'))
const ClaimTokens = lazy(() => import('views/ClaimTokens'))
const Vouchers = lazy(() => import('views/Vouchers'))
const EnergyCore = lazy(() => import('views/ConvertCore'))
const ECoinsCard = lazy(() => import('views/ECoinsCard'))
const Staking = lazy(() => import('views/Staking'))
const NotFound = lazy(() => import('views/NotFound'))
const Maintain = lazy(() => import('./views/Maintain'))

const App: React.FC = () => {
  const [init, setInit] = useState(true)
  const [loadedTime, setLoadedTime] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      if (loadedTime < 1200) {
        setLoadedTime((lT) => lT + 500)
      }
    }, 500)
  }, [loadedTime])

  useEffect(() => {
    const hidePreloading = (check?: boolean) => {
      const preloading: any = document.getElementsByClassName('preloading')[0]

      if (preloading && check) {
        const fadeEffect = setInterval(() => {
          if (!preloading.style.opacity) {
            preloading.style.opacity = 1
          }
          if (preloading.style.opacity === '1') {
            preloading.style.opacity = 0
          } else {
            clearInterval(fadeEffect)
            preloading.style.display = 'none'
          }
        }, 200)
      }
    }

    setInit(false)

    window.addEventListener('load', () => {
      const image = document.querySelector('img')
      const isLoaded = image.complete && image.naturalHeight !== 0
      if (loadedTime > 1668) {
        hidePreloading(isLoaded)
      } else {
        setTimeout(() => {
          hidePreloading(isLoaded)
        }, 1888)
      }
    })
  }, [loadedTime])

  if (init) return <></>

  return (
    <>
      <ResetCSS />
      <GlobalStyle />
      <SuspenseWithChunkError fallback={<PageLoader />}>
        <Routes>
          <Route path="/home" element={<Navigate to="/" />} />
          <Route path="/mera" element={<Navigate to="/" />} />
          <Route path="/playing" element={<Navigate to="/game" />} />
          <Route path="/" element={<MeraHome />} />
          <Route path="/game" element={<Playing />} />
          <Route path="/maintain" element={<Maintain />} />
          <Route element={<DefaultLayout />}>
            <Route path="/account" element={<ConfigAccount />} />

            <Route path="/tokens">
              <Route index element={<ClaimTokens />} />
              <Route path="staking" element={<Staking />} />
            </Route>
            <Route path="/event">
              <Route index element={<Vouchers />} />
              <Route path="convert-core" element={<EnergyCore />} />
              <Route path="ecoins-card" element={<ECoinsCard />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
        <ScrollToTop />
        <ToastListener />
      </SuspenseWithChunkError>
    </>
  )
}

export default React.memo(App)
