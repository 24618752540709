import React from 'react'
import Typography from 'components/Typography'
import tokens from 'config/constants/tokens'
import useTokenBalance from 'hooks/useTokenBalance'

import styled from 'styled-components'
import { decimalFloorFormat, DECIMAL_PLACES } from 'utils/bigNumber'

const BalanceStyles = styled.div`
  position: absolute;
  bottom: 90px;
  left: 40px;

  .currency-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    margin-top: 10px;
    p {
      font-family: 'UTM Bebas';
      font-style: normal;
      font-weight: 400;
      font-size: 16px !important;
      line-height: 18px;
    }
    .icon {
      width: 24px;
      font-size: 24px;
      margin-right: 12px;
    }
  }
  @media only screen and (max-width: 768px) {
    right: 7%;
    left: unset;
  }
`

const { REACT_APP_CHAIN_ID } = process.env
const Balance = () => {
  const { fiwa, busd, usdt } = tokens
  const { balance: fiwaBalance } = useTokenBalance(fiwa.address[+REACT_APP_CHAIN_ID])
  const { balance: busdBalance } = useTokenBalance(busd.address[+REACT_APP_CHAIN_ID])
  const { balance: usdtBalance } = useTokenBalance(usdt.address[+REACT_APP_CHAIN_ID])

  return (
    <BalanceStyles>
      <div className="currency-group">
        <img src="/images/tokens/USDT.svg" alt="fiwa" className="icon" />
        <Typography size="small" className="value">
          {decimalFloorFormat(usdtBalance.dividedBy(1e18).toNumber(), DECIMAL_PLACES)}
        </Typography>
      </div>
      <div className="currency-group">
        <img src="/images/tokens/BUSD.svg" alt="fiwa" className="icon" />
        <Typography size="small" className="value">
          {decimalFloorFormat(busdBalance.dividedBy(1e18).toNumber(), DECIMAL_PLACES)}
        </Typography>
      </div>
      <div className="currency-group">
        <img src="/images/FIWA.png" alt="fiwa" className="icon" />
        <Typography size="small" className="value">
          {decimalFloorFormat(fiwaBalance.dividedBy(1e18).toNumber(), DECIMAL_PLACES)}
        </Typography>
      </div>
    </BalanceStyles>
  )
}

export default Balance
