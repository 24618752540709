export interface INavbar {
  key: number
  title: string
  pathName: string
}

interface ISideBar {
  [key: string]: INavbar[]
}

export interface ISubItem {
  title: string
  pathName: string
  sub: number
}
interface ISideBarMobile extends INavbar {
  subMenu?: ISubItem[]
}

export const navbar: INavbar[] = [
  {
    key: 1,
    title: 'GAME',
    pathName: '/game',
  },
  {
    key: 2,
    title: 'TOKENS',
    pathName: '/tokens',
  },
  {
    key: 3,
    title: 'EVENT',
    pathName: '/event',
  },
]

export const sidebar = [
  {
    key: 1,
    title: 'CONFIGURE ACCOUNT',
    pathName: '/account',
  },

  {
    key: 3,
    title: 'BUY AND CLAIM',
    pathName: '/claim-tokens',
  },
  {
    key: 6,
    title: 'VOUCHERS',
    pathName: '/vouchers',
  },
  {
    key: 4,
    title: 'CONVERT CORE',
    pathName: '/convert-core',
  },
  {
    key: 8,
    title: 'E-FIWA CARD',
    pathName: '/ecoins-card',
  },
  {
    key: 5,
    title: 'STAKING',
    pathName: '/staking',
  },
]

export const sidebarWithRoute: ISideBar = {
  ACCOUNT: [],
  EVENT: [
    {
      key: 1,
      title: 'VOUCHERS',
      pathName: '',
    },
    {
      key: 2,
      title: 'CONVERT CORE',
      pathName: '/convert-core',
    },
    {
      key: 3,
      title: 'E-FIWA CARD',
      pathName: '/ecoins-card',
    },
  ],
  TOKENS: [
    {
      key: 1,
      title: 'BUY AND CLAIM',
      pathName: '',
    },
    {
      key: 2,
      title: 'STAKING',
      pathName: '/staking',
    },
  ],
}

export const sidebarMobile: ISideBarMobile[] = [
  {
    key: 0,
    title: 'GAME',
    pathName: '/game',
  },
  {
    key: 1,
    title: 'ACCOUNT',
    pathName: '/account',
  },
  {
    key: 2,
    title: 'TOKENS',
    pathName: '',
    subMenu: [
      {
        sub: 0,
        title: 'BUY AND CLAIM',
        pathName: '/tokens',
      },
      {
        sub: 1,
        title: 'STAKING',
        pathName: '/tokens/staking',
      },
    ],
  },
  {
    key: 3,
    title: 'EVENT',
    pathName: '',
    subMenu: [
      {
        sub: 0,
        title: 'VOUCHERS',
        pathName: '/event',
      },
      {
        sub: 1,
        title: 'CONVERT CORE',
        pathName: '/event/convert-core',
      },
      {
        sub: 2,
        title: 'E-FIWA CARD',
        pathName: '/event/ecoins-card',
      },
    ],
  },
]

export const RoutesName = {
  GAME: '/game',
  MARKETPLACE: '/marketplace',
  DOWNLOAD: 'none',
  CONFIGURE_ACCOUNT: '/account',
  CLAIM_TOKENS: '/claim-tokens',
  E_FIWA_CARD: '/ecoins-card',
  STAKING: '/staking',
}
