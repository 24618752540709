import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap/uikit/dist/theme'
import './animation.scss'
import './tooltip.css'
import Media from 'utils/media'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle<{ backgroundPosition?: string; maintain?: boolean }>`
  * {
    font-family: 'Montserrat';
    font-size: 14px;
  }
  html{
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: #141414;
    /* background-image: url('/mera/background.png'); */
    background-image: url('/mera/background.png') no-repeat center center fixed;
    background-size: cover;
    background-blend-mode: color;
    background-position: center;
      -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  }
 button{
  cursor: pointer;
  border:none;
  outline:none;
 }
  body {
    width: 100%;
    /* background-image: url('/mera/background.png'); */
    mix-blend-mode: revert;
    background-repeat: no-repeat;
    /* background-size: auto; */
    background-size: cover;
    background-position: ${({ backgroundPosition }) => backgroundPosition || 'center center'};
    scroll-behavior: smooth;
    overflow: hidden;
    height: 100%;
    padding: 0;
    margin: 0;
    transition: all 0.5s ease;

    // ${Media.greaterThan(Media.SIZE.XXL2)} {
    //   background-size: cover;
    // }
    // ${Media.lessThan(Media.SIZE.SM)} {
    //   background-size: cover;
    // }

    #root{
      height: 100%;
      display: flex;
      overflow: hidden;
    }

    img {
      /* height: auto; */
      max-width: 100%;
      user-select: none;
    }

    div[role="presentation"] {
      background-color: rgba(0, 0, 0, 0.9);
    
    }
  }

  .style-scrollbar, * {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #113879;
      border-radius: 10px;

      &:hover {
        background-color: #144693;
      }
    }
  }

  // #particles-js {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  // }
`

export default GlobalStyle
