import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import { COLORS } from 'utils'
import Media from 'utils/media'

const StyledP = styled.div<TypographyProps>`
  text-align: ${(props) => props.align || 'left'};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  line-height: ${(props) => props.lineHeight || 'normal'};
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : COLORS.TEXT_PRIMARY)};
  letter-spacing: 0.02em;
  // text-shadow: 1px 1px 1px rgba(0,0,0,0.25);
  &.changaFont {
    font-family: 'Changa One', cursive;
  }
  &.center {
    text-align: center;
  }

  &.tiny {
    font-size: 10px;
  }
  &.small {
    font-size: 12px;
  }
  &.medium {
    font-size: 14px; // for default
  }
  &.large {
    font-size: 16px;
  }
  &.big {
    font-size: 18px;
  }
  &.huge {
    font-size: 20px;
  }
  &.giant {
    font-size: 24px;
  }
  &.enormous {
    font-size: 32px;
  }
  &.mega {
    font-size: 36px;
  }
  &.colossal {
    font-size: 40px;
  }

  &.link {
    cursor: pointer;
    user-select: none;
  }
  &.linkText {
    color: ${COLORS.LINK};
  }
  &.secondary {
    color: ${COLORS.TEXT_SECONDARY};
  }
  &.underline {
    text-decoration: underline;
  }
  &.italic {
    font-style: italic;
  }

  &.ellipsis {
    white-space: nowrap;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
  }

  &.highlight {
    position: relative;
    font-family: 'Conthrax';
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(calc(-100% - 20px), -50%);
      width: 82px;
      height: 4px;
      background-color: ${COLORS.BLUE};

      ${Media.lessThan(Media.SIZE.LG)} {
        bottom: -50%;
        transform: translate(0, 1em);
      }
    }
  }
`
type BooleanFalsy = boolean | '' | `` | '' | undefined | null | 0 | -0 | 0n // for easy conditional prop

type TypographyProps = {
  as?: any
  className?: string
  style?: React.CSSProperties
  align?: string
  center?: BooleanFalsy

  // text styles:
  color?: string
  lineHeight?: string
  size?:
    | 'tiny' // 10px
    | 'small' // 12px
    | 'medium' // 14px
    | 'large' // 16px
    | 'big' // 18px
    | 'huge' // 20px
    | 'giant' // 24px
    | 'enormous' // 32px
    | 'mega' // 36px
    | 'colossal' // 40px
    | '' // for easy conditional prop
  underline?: BooleanFalsy
  italic?: BooleanFalsy
  bold?: BooleanFalsy
  highlight?: BooleanFalsy
  // types:
  link?: BooleanFalsy
  linkText?: BooleanFalsy // color: COLORS.LINK
  secondary?: BooleanFalsy // color: COLORS.TEXT_SECONDARY

  // font
  changaFont?: BooleanFalsy // default is Montserrat, sans-serif
  ellipsis?: boolean // when you use ellipsis, you need set max-width for Typography or parent components
  children: any
} & React.HTMLAttributes<HTMLElement>

const Typography = ({
  //
  className,
  children,
  link,
  underline,
  italic,
  secondary,
  center,
  size,
  changaFont,
  ellipsis,
  highlight,
  as,
  ...rest
}: TypographyProps) => {
  return (
    <StyledP
      as={
        as ||
        (size === 'enormous' || size === 'mega' || size === 'colossal'
          ? 'h1'
          : size === 'giant'
          ? 'h2'
          : size === 'huge'
          ? 'h3'
          : 'p')
      }
      className={classnames(
        size || '',
        {
          link,
          underline,
          italic,
          secondary,
          center,
          changaFont,
          ellipsis,
          highlight,
        },
        'typography',
        className,
      )}
      {...rest}
    >
      {children}
    </StyledP>
  )
}
export default Typography
