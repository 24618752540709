import styled from 'styled-components'
import React, { useState, useCallback } from 'react'

const ButtonDownloadStyles = styled.button`
  font-family: 'UTM Bebas';
  position: relative;
  white-space: normal;
  color: inherit;
  font-weight: 400;
  cursor: pointer;
  font-size: 17px;
  display: block;
  align-items: center;
  background-color: unset;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    margin-left: 8px;
    margin-top: 5px;
  }
`
const DropdownStyles = styled.div`
  position: absolute;
  top: calc(100% + 28px);
  right: 50%;
  transform: translateX(50%);
  padding: 24px 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  background: linear-gradient(65.38deg, rgba(0, 16, 99, 0.65) 0%, rgba(0, 37, 95, 0.35) 100%);

  border: 1px solid #0057e2;
  backdrop-filter: blur(3.5px);

  border-radius: 4px;

  width: 120px;
  height: 112px;
  .option__box {
    display: flex;
    gap: 8px;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
    & > span {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      /* or 171% */

      display: flex;
      align-items: center;

      color: #ffffff;
    }
  }
  /* animation: fadeIn 0.1s linear;
  @keyframes fadeIn {
    0% {
      opacity: 0;
      width: 0;
    }
    100% {
      opacity: 1;
      width: 120px;
    }
  } */
`
interface propsDownload {
  displayBlock?: string
  paddingCss?: string
  marginCss?: string
}
const Download = ({ displayBlock, paddingCss, marginCss }: propsDownload) => {
  const [show, setShow] = useState(false)
  const dropdownRef = React.useRef(null)
  const buttonRef = React.useRef(null)

  const handleClickOutside = useCallback(
    (event) => {
      if (buttonRef.current?.contains(event.target)) {
        setShow(!show)
      }
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
        setShow(!show)
      }
    },
    [show],
  )

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  const handleOpenLinkBlank = (url) => {
    window.open(url)
  }

  return (
    <ButtonDownloadStyles
      ref={buttonRef}
      type="button"
      style={{ color: 'white', position: 'relative', display: displayBlock, padding: paddingCss, margin: marginCss }}
      className="btn__dowload"
    >
      DOWNLOAD
      <img src="/mera/arrow_dropdown.svg" alt="icon__download" />
      {show && (
        <DropdownStyles ref={dropdownRef}>
          <div
            onMouseDown={() => handleOpenLinkBlank(process.env.REACT_APP_DOWNLOAD_APP_WINDOW)}
            className="option__box"
            role="toolbar"
            aria-label="link download"
          >
            <img src="/mera/MICROSOFT.svg" alt="" />
            <span>Windows</span>
          </div>
          <div
            onMouseDown={() => handleOpenLinkBlank(process.env.REACT_APP_DOWNLOAD_APP_MACOS)}
            className="option__box"
            role="toolbar"
            aria-label="link download"
          >
            <img src="/mera/APPLE.svg" alt="" />
            <span>Mac OS</span>
          </div>
        </DropdownStyles>
      )}
    </ButtonDownloadStyles>
  )
}

export default Download
