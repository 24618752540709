import React, { useEffect, useState } from 'react'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { useWeb3Auth } from 'contexts/Web3AuthContext'
import { useWalletModal } from 'components/Menu/WalletModal'
import useAuth, { useLogin } from 'hooks/useAuth'
import Download from 'components/Menu/Download'
import styled from 'styled-components'
import { ContainerModalStyles } from 'views/ConfigAccount/styled/Styled'
import ConnectModal from 'components/Menu/WalletModal/ConnectModal'
import MsidebarRight from 'components/Menu/MsidebarRight'
import ConnectButton from 'components/ConnectButton'
import { navbar } from 'config/constants/menu'
import MsidebarLeft from 'components/Menu/MsidebarLeft'
import { useMaintainProvider } from 'contexts/MaintainContext'
import Loading from 'components/Loading'
import { HOW_TO_PLAY_URL } from 'config/constants/mera-home'
import { ConfigAccountContext } from 'contexts/ConfigAccount/ConfigAccountContext'
import { ActionCenterStyles, HeaderStyles, MainDivStyles } from './styled'
import Sidebar from './components/sidebar'

const ContentStyles = styled.div`
  width: 100%;
  @media only screen and (min-width: 769px) {
    width: calc(100% - 250px);
  }
`

const DefaultLayout = () => {
  const { initing, maintain } = useMaintainProvider()
  const ORIGINAL_URL = window.location.origin
  const navigate = useNavigate()
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { logined, loginType, loading } = useLogin()
  const { account: accountWeb3Auth, initializing } = useWeb3Auth()
  const [showMenuLeft, setShowMenuLeft] = useState(false)
  const [showMenuRight, setShowMenuRight] = useState(false)
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(login, logout, account || accountWeb3Auth)
  const { pathname } = useLocation()
  const pathNameCurrent = pathname.split('/')[1]
  useEffect(() => {
    document.getElementsByTagName('html')[0].style.backgroundImage = `url('/mera/bgapp.png')`
    document.getElementsByTagName('body')[0].style.backgroundImage = `url('/mera/bgapp.png')`
  }, [])

  const handleShowSideBarMobile = (typeShow: string) => {
    if (typeShow === 'left') {
      setShowMenuLeft(!showMenuLeft)
      setShowMenuRight(false)
    } else {
      setShowMenuRight(!showMenuRight)
      setShowMenuLeft(false)
    }
  }

  React.useEffect(() => {
    if (!initing) {
      if (maintain) navigate('/maintain', { replace: true })
    }
  }, [navigate, initing, maintain])

  return (
    <ConfigAccountContext>
      <>
        {initializing || loading ? (
          <Loading size="small" className="loading-box" />
        ) : (
          <>
            {logined ? (
              <>
                <MainDivStyles>
                  <HeaderStyles>
                    <div className="group__header__menu">
                      <button
                        style={{ background: 'none', border: 'none', outline: 'none' }}
                        type="button"
                        onMouseDown={() => handleShowSideBarMobile('left')}
                        tabIndex={0}
                        className="icon__menu__mobile"
                      >
                        <img src="/mera/ICON_MENU.svg" alt="menu_icon" style={{ cursor: 'pointer' }} />
                      </button>

                      <NavLink
                        className="logo"
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        to="/"
                      >
                        <img
                          src={`${ORIGINAL_URL}/mera/logo.png` || '/mera/logo.png'}
                          alt="logo"
                          style={{ objectFit: 'cover' }}
                        />
                      </NavLink>
                    </div>

                    <nav className="navbar-list">
                      <ul className="navbar-list__item" style={{ listStyle: 'none' }}>
                        {navbar.map((item) => (
                          <li className="navbar-list__item__link" key={item.title}>
                            <NavLink
                              style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                              className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                              to={item.pathName}
                            >
                              {item.title === 'GAME' && <span className="play-now">PLAY NOW</span>}
                              {item.title}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                      <ActionCenterStyles>
                        <a className="how-to-play" href={HOW_TO_PLAY_URL} target="_blank" rel="noopener noreferrer">
                          HOW TO PLAY?
                        </a>
                        <Download />

                        {(account || accountWeb3Auth) && logined ? (
                          <>
                            <NavLink to="/account" style={{ cursor: 'pointer' }} className="user__header__icon">
                              <img src="/mera/USER_HEADER.svg" alt="" />
                            </NavLink>
                            <button
                              className="user__header__icon__mobile"
                              type="button"
                              onClick={() => handleShowSideBarMobile('right')}
                              style={{ backgroundColor: 'unset', border: 'none', cursor: 'pointer', outline: 'none' }}
                            >
                              <img src="/mera/USER_HEADER.svg" alt="" />
                            </button>
                            <ConnectButton
                              onClick={onPresentAccountModal}
                              account={account || accountWeb3Auth}
                              logined={logined}
                              loginType={loginType}
                            />
                          </>
                        ) : (
                          <ConnectButton onClick={onPresentConnectModal} />
                        )}
                      </ActionCenterStyles>
                    </nav>
                  </HeaderStyles>

                  <main>
                    <Sidebar renderByPathName={pathNameCurrent} />
                    <ContentStyles>
                      <Outlet />
                    </ContentStyles>
                  </main>
                </MainDivStyles>
                <MsidebarRight open={showMenuRight} handleClose={() => setShowMenuRight(!showMenuRight)} />
                <MsidebarLeft open={showMenuLeft} handleClose={() => setShowMenuLeft(!showMenuLeft)} />
              </>
            ) : (
              // account modal will be show if you not login
              <ContainerModalStyles>
                <div className="box__modal__move">
                  <ConnectModal hideCloseButton onBack={() => navigate('/')} login={login} logout={logout} />
                </div>
              </ContainerModalStyles>
            )}
          </>
        )}
      </>
    </ConfigAccountContext>
  )
}
export default DefaultLayout
