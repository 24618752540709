import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { SerializedBigNumber } from 'state/types'

export const BIG_ZERO = new BigNumber(0)
export const BIG_ONE = new BigNumber(1)
export const BIG_NINE = new BigNumber(9)
export const BIG_TEN = new BigNumber(10)

export const ethersToSerializedBigNumber = (ethersBn: ethers.BigNumber): SerializedBigNumber =>
  ethersToBigNumber(ethersBn).toJSON()

export const ethersToBigNumber = (ethersBn: ethers.BigNumber): BigNumber => new BigNumber(ethersBn.toString())

const fmt = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
}
export const DECIMAL_PLACES = 2
export const decimalFloorFormat = (number: number, decimalPlaces: number) => {
  BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_FLOOR, FORMAT: fmt })
  const bNumber = new BigNumber(number.toString())
  return bNumber.toFormat(decimalPlaces)
}
