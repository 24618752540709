import { initializeApp } from 'firebase/app'
import { getFirestore, doc, onSnapshot } from "firebase/firestore"
import { getAuth } from "firebase/auth";

initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
})

const auth = getAuth()
const db = getFirestore()

const firestore = {
  db,
  onSnapshot,
  maintain: (page: 'market' | 'app') => doc(db, 'maintain', page)
}

export {
  firestore,
  auth
}
