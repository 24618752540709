import React from 'react'
import { ToastContainer } from 'react-toastify'
import './toast-styles.css'
import styled from 'styled-components'
import Media from 'utils/media'

const StyledToastContainer = styled(ToastContainer)`
  width: auto;

  .body {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    border-radius: 4px;

    & * {
      color: white;
    }
    &.info,
    &.default {
      background: #096dd9;
    }
    &.error {
      background: #cf1322;
    }
    &.success {
      background: #389e0d;
    }
    &.warning {
      background: #d4b106;
    }
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    width: auto;
  }
`
const ToastListener = () => {
  return (
    <StyledToastContainer
      bodyClassName={({ type }) => {
        return `body ${type}`
      }}
      position="top-center"
      autoClose={3868}
      limit={3}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  )
}

export default ToastListener
