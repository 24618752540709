export type TCURRENCY = 'USDT' | 'BUSD' | 'FIWA'

export const CURRENCIES: {
  USDT: TCURRENCY
  BUSD: TCURRENCY
  FIWA: TCURRENCY
} = {
  USDT: 'USDT',
  BUSD: 'BUSD',
  FIWA: 'FIWA',
}
