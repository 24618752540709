import tokens from './tokens'
import { FarmConfig, FarmCategory } from './types'

const farms: FarmConfig[] = [
  // {
  //   pid: 0,
  //   lpSymbol: 'FIWA - USDT',
  //   tokenId: 1,
  //   category: FarmCategory.LP,
  //   lpAddresses: {
  //     97: '0x161bF5BB9095Cdd51F8d1EA73A8DcCb813a32455',
  //     56: '0xA0a65F7EdE45F0b0420b50ffBB2e37A6c97D1E7B',
  //   },
  //   token: tokens.fiwa,
  //   quoteToken: tokens.usdt,
  // },
  {
    pid: 1,
    lpSymbol: 'FIWA - BUSD',
    tokenId: 2,
    category: FarmCategory.LP,
    lpAddresses: {
      97: '0xe9f3f7E2Fc9cFB5Bb95f8C417d2a23Ac34538873',
      56: '0xdB279ba6D7Fd9bfa3d8991ad7d451DED2A701d5b',
    },
    token: tokens.fiwa,
    quoteToken: tokens.busd,
  },
  {
    pid: 2,
    lpSymbol: 'CWIG - BUSD',
    tokenId: 3,
    category: FarmCategory.LP,
    lpAddresses: {
      97: '0x4f0db165D03fF151660d1B9ef5075C94b5a74228',
      56: '0xcC424F786fF76ac4e2dba069181472fD0F5bA154',
    },
    token: tokens.cwig,
    quoteToken: tokens.busd,
  },
]

export default farms
