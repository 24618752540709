import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { Web3Auth } from '@web3auth/modal'
import { utils } from 'ethers'
import { ADAPTER_EVENTS, CHAIN_NAMESPACES, SafeEventEmitterProvider, WALLET_ADAPTER_TYPE } from '@web3auth/base'
import { Web3AuthCore } from '@web3auth/core'
import { NetworkSwitch } from '@web3auth/ui'
import { OpenloginAdapter } from '@web3auth/openlogin-adapter'
import { WalletConnectV1Adapter } from '@web3auth/wallet-connect-v1-adapter'
import QRCodeModal from '@walletconnect/qrcode-modal'
import { LOGIN_PROVIDER_TYPE } from '@toruslabs/openlogin'
import getNodeUrl from 'utils/getRpcUrl'
import Web3 from 'web3'
import Storage from 'utils/storage'
import { web3AuthLogin } from 'api/auth'
import Request from 'utils/request'
import { useLogin } from 'hooks/useAuth'
import useToast from 'hooks/useToast'
import { web3AuthConnectors } from 'components/Menu/WalletModal/config'

const rpcTarget = getNodeUrl()
const chainId = +process.env.REACT_APP_CHAIN_ID
// get from https://dashboard.web3auth.io
const clientId = process.env.REACT_APP_WEB3AUTH_CLIENT_ID
// current chain config
const currentChainConfig = {
  displayName: chainId === 56 ? 'BSC Mainnet' : 'BSC Testnet',
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: utils.hexValue(+chainId),
  rpcTarget,
  blockExplorer: chainId === 56 ? 'https://bscscan.com/' : 'https://testnet.bscscan.com/',
  ticker: 'BNB',
  tickerName: 'Binance',
}

const Web3AuthContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  login: async (adapter: WALLET_ADAPTER_TYPE, loginProvider: LOGIN_PROVIDER_TYPE, loginHint?: string) => null,
  logout: async () => null,
  provider: {},
  initializing: false,
  account: '',
  privKey: '',
})
export const useWeb3Auth: any = () => useContext(Web3AuthContext)

const Web3AuthProvider: React.FC<any> = ({ children }) => {
  const { toastError } = useToast()
  const { setLogined, setLoginType } = useLogin()
  const [initializing, setinitializing] = useState<boolean>(false)
  const [web3Auth, setWeb3Auth] = useState<Web3Auth | null>(null)
  const [provider, setProvider] = useState<SafeEventEmitterProvider | null>(null)
  const [account, setAccount] = useState<string>('')
  const [privKey, setPrivKey] = useState<string>('')

  const getUserWallet = useCallback(async () => {
    if (!provider) {
      // console.log('provider not initialized yet')
      setPrivKey('')
      setAccount('')
      return
    }

    const privateKey: string = await provider.request({
      method: 'eth_private_key',
    })

    if (privateKey) {
      setPrivKey(privateKey)
    } else {
      setPrivKey('')
    }
  }, [provider])

  const logout = async () => {
    if (!web3Auth) {
      // console.log('web3auth not initialized yet')
      return
    }

    if (!provider) {
      // console.log('not login yet')
      return
    }

    await web3Auth.logout()
    setProvider(null)
    setPrivKey('')
    setAccount('')
  }

  const onWeb3AuthLoginSuccess = useCallback(
    async (providerWeb3Auth: any) => {
      setinitializing(true)
      try {
        const web3Instance = new Web3(providerWeb3Auth as any)
        // Get user's Ethereum public address
        const address = (await web3Instance.eth.getAccounts())[0]
        setAccount(address)

        const openLoginStore = Storage.get('openlogin_store')
        const { data, success } = await web3AuthLogin({
          aggregateVerifier: openLoginStore?.aggregateVerifier,
          appKey: process.env.REACT_APP_WEB3AUTH_CLIENT_ID,
          publicAddress: address,
          idToken: openLoginStore?.idToken,
          verifierId: openLoginStore?.verifierId,
        })
        if (!success) throw data

        Storage.set('LOGIN_RESULT', { ...data, address })
        Storage.set('ACCESS_TOKEN', data.accessToken)
        Storage.set('PUBLIC_ADDRESS', address)
        Request.setAccessToken(data.accessToken)

        setLogined(true)
        const connector = web3AuthConnectors?.find((e) => e?.loginProvider === Storage.get('connectorId'))
        if (connector) setLoginType({ title: connector?.title, icon: connector?.whiteIcon })
      } catch (e: any) {
        toastError(e?.message || 'Something went wrong')
      } finally {
        setinitializing(false)
      }
    },
    [setLoginType, setLogined, toastError],
  )

  const login = useCallback(
    async (adapter: WALLET_ADAPTER_TYPE, loginProvider: LOGIN_PROVIDER_TYPE) => {
      try {
        if (!web3Auth) {
          throw new Error('web3auth not initialized yet')
        }
        setinitializing(true)

        const localProvider = await web3Auth.connectTo(adapter, { loginProvider })
        setProvider(localProvider)
      } catch (error: any) {
        if (error?.code === 5111) {
          // already login web3auth
          if (provider) {
            await onWeb3AuthLoginSuccess(provider)
          } else {
            localStorage.clear()
            Storage.clear()
            window.location.reload()
          }
        } else if (error?.code === 5114) {
          // user close popup
          setinitializing(false)
        } else {
          setinitializing(false)
          toastError(error?.message || 'Something went wrong')
        }
      }
    },
    [onWeb3AuthLoginSuccess, provider, toastError, web3Auth],
  )

  useEffect(() => {
    getUserWallet()
  }, [getUserWallet])

  useEffect(() => {
    const subscribeAuthEvents = (instance: Web3AuthCore) => {
      instance.on(ADAPTER_EVENTS.CONNECTED, async () => {
        // console.log('Yeah!, you are successfully logged in', data)
        setProvider(instance.provider)
        await onWeb3AuthLoginSuccess(instance.provider)
      })

      instance.on(ADAPTER_EVENTS.CONNECTING, () => {
        // console.log('connecting')
      })

      instance.on(ADAPTER_EVENTS.DISCONNECTED, () => {
        // console.log('disconnected')
      })

      instance.on(ADAPTER_EVENTS.ERRORED, (error: unknown) => {
        console.error('some error or user has cancelled login request', error)
      })
    }

    async function init() {
      try {
        if (Storage.get('WALLET') === 3) setinitializing(true)

        const web3AuthInstance = new Web3AuthCore({
          chainConfig: currentChainConfig,
          enableLogging: false,
          clientId,
        })

        await subscribeAuthEvents(web3AuthInstance)

        const adapter = new OpenloginAdapter({ adapterSettings: { clientId } })
        const networkUi = new NetworkSwitch()
        const wcAdapter = new WalletConnectV1Adapter({
          adapterSettings: { qrcodeModal: QRCodeModal, networkSwitchModal: networkUi },
          chainConfig: currentChainConfig,
        })

        web3AuthInstance.configureAdapter(adapter)
        web3AuthInstance.configureAdapter(wcAdapter)

        await web3AuthInstance.init()
        setWeb3Auth(web3AuthInstance as any)

        if (!web3AuthInstance?.provider) setinitializing(false)
      } catch (error) {
        console.error(error)
      }
    }
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const contextProvider = {
    initializing,
    provider,
    account,
    privKey,
    login,
    logout,
  }

  return <Web3AuthContext.Provider value={contextProvider}>{children}</Web3AuthContext.Provider>
}

export default Web3AuthProvider
