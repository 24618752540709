import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 844.7 558.2" {...props}>
      <defs>
        <path id="prefix__a" d="M0 0h844.7v558.2H0z" />
      </defs>
      <clipPath id="prefix__b">
        <use xlinkHref="#prefix__a" overflow="visible" />
      </clipPath>
      <path
        d="M172.9 121.4c137.7-134.9 361.1-134.9 498.8 0l16.6 16.2c6.9 6.7 6.9 17.7 0 24.4l-56.7 55.5c-3.4 3.4-9 3.4-12.5 0l-22.8-22.3c-96.1-94.1-251.9-94.1-348 0l-24.4 23.9c-3.4 3.4-9 3.4-12.5 0l-56.7-55.5c-6.9-6.7-6.9-17.7 0-24.4l18.2-17.8zM789 236.2l50.5 49.4c6.9 6.7 6.9 17.7 0 24.4L611.9 532.9c-6.9 6.7-18.1 6.7-24.9 0L425.4 374.8c-1.7-1.7-4.5-1.7-6.2 0L257.7 532.9c-6.9 6.7-18.1 6.7-24.9 0L5.2 310.1c-6.9-6.7-6.9-17.7 0-24.4l50.5-49.4c6.9-6.7 18.1-6.7 24.9 0l161.5 158.1c1.7 1.7 4.5 1.7 6.2 0l161.5-158.1c6.9-6.7 18.1-6.7 24.9 0l161.5 158.1c1.7 1.7 4.5 1.7 6.2 0l161.5-158.1c7.1-6.8 18.2-6.8 25.1-.1"
        clipPath="url(#prefix__b)"
        fill="#3a99fb"
      />
    </Svg>
  )
}

export default Icon
