import { ChainId } from '@defi-warrior/sdk'
import stgAddresses from 'config/constants/contracts'
import devAddresses from 'config/constants/contracts.dev'
import tokens from 'config/constants/tokens'
import { Address } from 'config/constants/types'

const addresses = process.env.REACT_APP_ENV === 'development' ? devAddresses : stgAddresses

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getCakeAddress = () => {
  return getAddress(tokens.cake.address)
}
export const getCwigAddress = () => {
  return getAddress(tokens.cwig.address)
}
export const getFiwaAddress = () => {
  return getAddress(tokens.fiwa.address)
}
export const getUsdtAddress = () => {
  return getAddress(tokens.usdt.address)
}
export const getBusdAddress = () => {
  return getAddress(tokens.busd.address)
}
export const getCwigShopAddress = () => {
  return getAddress(addresses.cwigShop)
}
export const getECoinsAddress = () => {
  return getAddress(addresses.eCoins)
}
export const getGemExchangeAddress = () => {
  return getAddress(addresses.gemExchange)
}
export const getExchangeAddress = () => {
  return getAddress(addresses.exchange)
}
export const getExchangeV2Address = () => {
  return getAddress(addresses.exchangeV2)
}
export const getNFTFactoryAddress = () => {
  return getAddress(addresses.nftFactory)
}
export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getNFTBodypartAddress = () => {
  return getAddress(addresses.nftBodyPart)
}
export const getNftManagerAddress = () => {
  return getAddress(addresses.nftManager)
}
export const getNftStakingPoolAddress = () => {
  return getAddress(addresses.nftStakingPool)
}
export const getCwigStakingPoolAddress = () => {
  return getAddress(addresses.cwigStakingPoolV1)
}
export const getFiwaStakingPoolAddress = () => {
  return getAddress(addresses.fiwaStakingPoolV1)
}
export const getCwigStakingPoolV2Address = () => {
  return getAddress(addresses.cwigStakingPoolV2)
}
export const getFiwaStakingPoolV2Address = () => {
  return getAddress(addresses.fiwaStakingPoolV2)
}
export const getFiwaNftStakingPoolAddress = () => {
  return getAddress(addresses.fiwaNftStakingPool)
}
export const getTwoDollarsTicketAddress = () => {
  return getAddress(addresses.twoDollarsTicket)
}
export const getWorldBossAddress = () => {
  return getAddress(addresses.worldBoss)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getWbnbAddress = () => {
  return getAddress(tokens.wbnb.address)
}
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2)
}
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}
export const getPancakeRabbitsAddress = () => {
  return getAddress(addresses.pancakeRabbits)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
}
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getTradingCompetitionAddress = () => {
  return getAddress(addresses.tradingCompetition)
}
export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft)
}
export const getCakeVaultAddress = () => {
  return getAddress(addresses.cakeVault)
}
export const getPredictionsAddress = () => {
  return getAddress(addresses.predictions)
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
export const getBunnySpecialCakeVaultAddress = () => {
  return getAddress(addresses.bunnySpecialCakeVault)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction)
}
export const getBinanceNFTAddress = () => {
  return getAddress(addresses.binanceNFT)
}
export const getLPFarmAddress = () => {
  return getAddress(addresses.lpFarm)
}
export const getRouterAddress = () => {
  return getAddress(addresses.router)
}
export const getIwoAddress = () => {
  return getAddress(addresses.iwo)
}
export const getLandBoxAddress = () => {
  return getAddress(addresses.landBox)
}
export const getFiwaFarmAddress = () => {
  return getAddress(addresses.fiwaFarm)
}
