import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { SidebarMobileLeftStyles } from 'components/Layout/AccountLayout/styled'
import styled from 'styled-components'
import { RiArrowDownSFill, RiArrowRightSFill } from 'react-icons/ri'
import { ISubItem, sidebarMobile } from 'config/constants/menu'
import Download from './Download'

interface Props {
  open: boolean
  handleClose: () => void
}
const SubMenuStyles = styled.div`
  margin-top: 15px;
  .sidebar-list__mobile__submenu {
    list-style: none;
    padding-left: 15px;
    &--item {
      margin-bottom: 10px;
      & > a {
        font-family: 'UTM Bebas';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #ffffff;
      }
    }
  }
`
const MsidebarLeft = ({ open, handleClose }: Props) => {
  const [bindIndex, setBindIndex] = React.useState(null)
  const { pathname } = useLocation()

  const handleToggle = (key: number) => {
    if (bindIndex === key) {
      setBindIndex(null)
    } else {
      setBindIndex(key)
    }
  }

  const STATE_DROPDOWN = {
    collapsed: {
      display: 'none',
    },
    expanded: {
      display: 'block',
    },
    colorActive: {
      color: '#FFC107',
    },
  }

  return (
    <SidebarMobileLeftStyles showMenuLeft={open}>
      <div
        className="sidebar_left__overlay"
        onClick={handleClose}
        tabIndex={0}
        aria-label="hide sidebar"
        onKeyDown={handleClose}
        role="button"
      />
      <ul className="sidebar-list__mobile left" style={{ listStyle: 'none' }}>
        {sidebarMobile.map((parentItem) => {
          const childSelected = parentItem?.subMenu?.find((item) => item.pathName === pathname)
          return (
            <li className="sidebar-list__mobile__item" key={parentItem.key}>
              {!parentItem.subMenu && (
                <NavLink
                  end
                  className={({ isActive }: any) => (isActive ? 'active' : 'inactive')}
                  to={parentItem.pathName}
                >
                  <img src="/mera/arrow_yellow.svg" alt="" className="icon__active" />
                  {parentItem.title}
                </NavLink>
              )}
              {parentItem.subMenu && parentItem.subMenu.length > 0 && (
                <div
                  className="dropdown__link--mobile"
                  onClick={() => handleToggle(parentItem.key)}
                  key={parentItem.key}
                  style={childSelected ? STATE_DROPDOWN.colorActive : {}}
                  tabIndex={0}
                  aria-label="hide sidebar"
                  onKeyDown={() => handleToggle(parentItem.key)}
                  role="button"
                >
                  {parentItem.title}
                  {bindIndex === parentItem.key ? (
                    <RiArrowDownSFill className="icon-dropdown-status" />
                  ) : (
                    <RiArrowRightSFill className="icon-dropdown-status" />
                  )}
                </div>
              )}
              {parentItem.subMenu && (
                <SubMenuStyles
                  style={bindIndex === parentItem.key ? STATE_DROPDOWN.expanded : STATE_DROPDOWN.collapsed}
                >
                  <ul className="sidebar-list__mobile__submenu">
                    {parentItem.subMenu.map((subItem: ISubItem) => (
                      <li className="sidebar-list__mobile__submenu--item" key={`subItem-${subItem.sub}`}>
                        <NavLink end to={`${parentItem.pathName}${subItem.pathName}`}>
                          {subItem.title}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </SubMenuStyles>
              )}
            </li>
          )
        })}
        <li>
          <Download displayBlock="flex" paddingCss="0" />
        </li>
      </ul>
    </SidebarMobileLeftStyles>
  )
}

export default React.memo(MsidebarLeft)
