import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="33.871" height="31.349" viewBox="0 0 33.871 31.349" {...props}>
      <g strokeLinecap="round" strokeLinejoin="round" strokeWidth={0.25}>
        <path data-name="Path 12" d="M32.083.125L18.949 9.843l2.442-5.727z" fill="#e17726" stroke="#e17726" />
        <path data-name="Path 13" d="M1.788.125l13.017 9.809-2.325-5.818z" fill="#e27625" stroke="#e27625" />
        <path
          data-name="Path 14"
          d="M27.354 22.658l-3.495 5.339 7.483 2.06 2.144-7.282z"
          fill="#e27625"
          stroke="#e27625"
        />
        <path data-name="Path 15" d="M.398 22.775l2.127 7.282 7.47-2.06-3.478-5.339z" fill="#e27625" stroke="#e27625" />
        <path data-name="Path 16" d="M9.595 13.64l-2.078 3.136 7.4.337-.247-7.969z" fill="#e27625" stroke="#e27625" />
        <path data-name="Path 17" d="M24.275 13.64l-5.157-4.587-.169 8.06 7.4-.337z" fill="#e27625" stroke="#e27625" />
        <path data-name="Path 18" d="M9.998 27.997l4.482-2.164-3.855-3.008z" fill="#e27625" stroke="#e27625" />
        <path data-name="Path 19" d="M19.391 25.833l4.469 2.164-.611-5.17z" fill="#e27625" stroke="#e27625" />
        <path data-name="Path 20" d="M23.86 27.997l-4.469-2.164.364 2.9-.039 1.231z" fill="#d5bfb2" stroke="#d5bfb2" />
        <path data-name="Path 21" d="M9.998 27.997l4.157 1.97-.03-1.231.351-2.9z" fill="#d5bfb2" stroke="#d5bfb2" />
        <path data-name="Path 22" d="M14.233 20.909l-3.715-1.084 2.624-1.205z" fill="#233447" stroke="#233447" />
        <path data-name="Path 23" d="M19.638 20.909l1.087-2.293 2.641 1.209z" fill="#233447" stroke="#233447" />
        <path data-name="Path 24" d="M9.998 27.997l.65-5.339-4.131.117z" fill="#cc6228" stroke="#cc6228" />
        <path data-name="Path 25" d="M23.225 22.658l.637 5.339 3.495-5.222z" fill="#cc6228" stroke="#cc6228" />
        <path
          data-name="Path 26"
          d="M26.354 16.776l-7.4.337.689 3.8 1.082-2.297 2.641 1.209z"
          fill="#cc6228"
          stroke="#cc6228"
        />
        <path
          data-name="Path 27"
          d="M10.518 19.825l2.624-1.205 1.091 2.294.688-3.8-7.4-.337z"
          fill="#cc6228"
          stroke="#cc6228"
        />
        <path data-name="Path 28" d="M7.517 16.776l3.108 6.049-.1-3.006z" fill="#e27525" stroke="#e27525" />
        <path data-name="Path 29" d="M23.366 19.825l-.117 3 3.1-6.051z" fill="#e27525" stroke="#e27525" />
        <path data-name="Path 30" d="M14.925 17.112l-.689 3.8.87 4.483.195-5.909z" fill="#e27525" stroke="#e27525" />
        <path data-name="Path 31" d="M18.949 17.112l-.364 2.358.182 5.922.87-4.483z" fill="#e27525" stroke="#e27525" />
        <path
          data-name="Path 32"
          d="M19.638 20.909l-.87 4.483.624.441 3.857-3.008.117-3.006z"
          fill="#f5841f"
          stroke="#f5841f"
        />
        <path
          data-name="Path 33"
          d="M10.518 19.825l.1 3.006 3.858 3.006.624-.441-.87-4.483z"
          fill="#f5841f"
          stroke="#f5841f"
        />
        <path
          data-name="Path 34"
          d="M19.716 29.967l.039-1.231-.338-.285h-4.963l-.329.285.026 1.231-4.157-1.97 1.455 1.192 2.953 2.036h5.053l2.962-2.034 1.442-1.192z"
          fill="#c0ac9d"
          stroke="#c0ac9d"
        />
        <path
          data-name="Path 35"
          d="M19.391 25.833l-.624-.441h-3.663l-.623.441-.356 2.903.325-.285h4.963l.338.285z"
          fill="#161616"
          stroke="#161616"
        />
        <path
          data-name="Path 36"
          d="M32.642 10.478l1.1-5.364L32.083.125 19.391 9.519l4.885 4.121 6.9 2.008 1.52-1.775-.663-.48 1.052-.959-.805-.622 1.052-.8z"
          fill="#763e1a"
          stroke="#763e1a"
        />
        <path
          data-name="Path 37"
          d="M.125 5.114l1.117 5.364-.715.531 1.065.8-.805.622 1.052.959-.663.48 1.52 1.775 6.9-2.008 4.885-4.121L1.788.125z"
          fill="#763e1a"
          stroke="#763e1a"
        />
        <path
          data-name="Path 38"
          d="M31.174 15.648l-6.9-2.008 2.079 3.136-3.1 6.051 4.105-.052h6.132z"
          fill="#f5841f"
          stroke="#f5841f"
        />
        <path
          data-name="Path 39"
          d="M9.595 13.64l-6.9 2.008-2.3 7.127h6.122l4.108.05-3.1-6.051z"
          fill="#f5841f"
          stroke="#f5841f"
        />
        <path
          data-name="Path 40"
          d="M18.949 17.113l.442-7.593 2-5.4h-8.912l2 5.4.442 7.593.169 2.384.013 5.9h3.664l.013-5.9z"
          fill="#f5841f"
          stroke="#f5841f"
        />
      </g>
    </Svg>
  )
}

export default Icon
