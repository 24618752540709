// @ts-nocheck
import cloneDeep from 'lodash/cloneDeep'
import isArray from 'lodash/isArray'
import keys from 'lodash/keys'

let isMobile = false
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator?.userAgent) || window.innerWidth < 768) {
  isMobile = true
}

export default class Misc {
  static trimObjectProperties = (obj, properties) => {
    const data = cloneDeep(obj)

    if (isArray(properties)) {
      properties.forEach((property) => {
        data[property] = data[property]?.trim()
      })
    } else {
      keys(obj).forEach((key) => {
        data[key] = data[key]?.trim()
      })
    }

    return data
  }

  static insert = (array, index, value) => (
    array.slice(0, index).concat(value).concat(array.slice(index, array.length))
  )

  static trimPublicAddress = (string, numberOfCharacter = 6) => string && (
    `${string.slice(0, numberOfCharacter)} . . . ${string.slice(string.length - numberOfCharacter, string.length)}`
  )

  static getStarsFromTier = (tier) => {
    const tierStars = tier === 3
      ? 1 : tier === 2
        ? 2 : tier === 1
          ? 3 : tier === 0
            ? 4 : tier === -1
              ? 5 : 0

    return tierStars
  }

  static isMobile = isMobile
}
