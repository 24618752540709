import { getInfoMaintain } from 'api/auth'
import React, { useState } from 'react'

const MaintainContext = React.createContext<{ maintain: boolean; initing: boolean }>({
  maintain: false,
  initing: false,
})
export const useMaintainProvider = () => React.useContext(MaintainContext)

function MaintainProvider({ children }) {
  const [initing, setIniting] = useState<boolean>(false)
  const [maintain, setMaintain] = useState<boolean>(false)
  React.useEffect(() => {
    const fetchInfoMaintain = async () => {
      try {
        setIniting(true)
        const { success, data } = await getInfoMaintain()
        if (success) {
          setMaintain(data)
        } else if (data?.error === 'MAINTAIN_STATE') {
          setMaintain(true)
        }
      } catch (error) {
        // console.log('catch: ', error)
      } finally {
        setIniting(false)
      }
    }

    const intervalId = setInterval(async () => {
      await fetchInfoMaintain()
    }, 10000)

    return () => clearInterval(intervalId)
  }, [])

  const provider = { maintain, initing }
  return <MaintainContext.Provider value={provider}>{children}</MaintainContext.Provider>
}

export default MaintainProvider
