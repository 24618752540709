import tokens from './tokens'
import { FarmConfig, FarmCategory } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 1, 2) should always be at the top of the file.
   */
  {
    pid: 0,
    tokenId: 1,
    category: FarmCategory.NFT,
    lpSymbol: 'FIWA-BTC LP',
    lpAddresses: {
      97: '0x34bBB4226626A75cC57Ae9cda6DD4C0aa29B0F28',
      56: '0xB4a8F1255C3AeB6bEB3C1c4273bDdBD8A9a0C6a8',
    },
    token: tokens.fiwa,
    quoteToken: tokens.btc,
  },
  {
    pid: 1,
    tokenId: 2,
    category: FarmCategory.NFT,
    lpSymbol: 'FIWA-BCH LP',
    lpAddresses: {
      97: '0x34bBB4226626A75cC57Ae9cda6DD4C0aa29B0F28',
      56: '0xB4a8F1255C3AeB6bEB3C1c4273bDdBD8A9a0C6a8',
    },
    token: tokens.fiwa,
    quoteToken: tokens.bch,
  },
  {
    pid: 2,
    tokenId: 3,
    category: FarmCategory.NFT,
    lpSymbol: 'FIWA-LTC LP',
    lpAddresses: {
      97: '0x34bBB4226626A75cC57Ae9cda6DD4C0aa29B0F28',
      56: '0xB4a8F1255C3AeB6bEB3C1c4273bDdBD8A9a0C6a8',
    },
    token: tokens.fiwa,
    quoteToken: tokens.ltc,
  },
  {
    pid: 3,
    tokenId: 4,
    category: FarmCategory.NFT,
    lpSymbol: 'FIWA-ETH LP',
    lpAddresses: {
      97: '0x34bBB4226626A75cC57Ae9cda6DD4C0aa29B0F28',
      56: '0xB4a8F1255C3AeB6bEB3C1c4273bDdBD8A9a0C6a8',
    },
    token: tokens.fiwa,
    quoteToken: tokens.eth,
  },
  {
    pid: 4,
    tokenId: 5,
    category: FarmCategory.NFT,
    lpSymbol: 'FIWA-BNB LP',
    lpAddresses: {
      97: '0x34bBB4226626A75cC57Ae9cda6DD4C0aa29B0F28',
      56: '0xB4a8F1255C3AeB6bEB3C1c4273bDdBD8A9a0C6a8',
    },
    token: tokens.fiwa,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 5,
    tokenId: 6,
    category: FarmCategory.NFT,
    lpSymbol: 'FIWA-UNI LP',
    lpAddresses: {
      97: '0x34bBB4226626A75cC57Ae9cda6DD4C0aa29B0F28',
      56: '0xB4a8F1255C3AeB6bEB3C1c4273bDdBD8A9a0C6a8',
    },
    token: tokens.fiwa,
    quoteToken: tokens.uni,
  },
  {
    pid: 6,
    tokenId: 7,
    category: FarmCategory.NFT,
    lpSymbol: 'FIWA-SOL LP',
    lpAddresses: {
      97: '0x34bBB4226626A75cC57Ae9cda6DD4C0aa29B0F28',
      56: '0xB4a8F1255C3AeB6bEB3C1c4273bDdBD8A9a0C6a8',
    },
    token: tokens.fiwa,
    quoteToken: tokens.fiwa,
  },
  {
    pid: 7,
    tokenId: 8,
    category: FarmCategory.NFT,
    lpSymbol: 'FIWA-LINK LP',
    lpAddresses: {
      97: '0x34bBB4226626A75cC57Ae9cda6DD4C0aa29B0F28',
      56: '0xB4a8F1255C3AeB6bEB3C1c4273bDdBD8A9a0C6a8',
    },
    token: tokens.fiwa,
    quoteToken: tokens.link,
  },
  {
    pid: 8,
    tokenId: 9,
    category: FarmCategory.NFT,
    lpSymbol: 'FIWA-WAVES LP',
    lpAddresses: {
      97: '0x34bBB4226626A75cC57Ae9cda6DD4C0aa29B0F28',
      56: '0xB4a8F1255C3AeB6bEB3C1c4273bDdBD8A9a0C6a8',
    },
    token: tokens.fiwa,
    quoteToken: tokens.fiwa,
  },
  {
    pid: 9,
    tokenId: 10,
    category: FarmCategory.NFT,
    lpSymbol: 'FIWA-ADA LP',
    lpAddresses: {
      97: '0x34bBB4226626A75cC57Ae9cda6DD4C0aa29B0F28',
      56: '0xB4a8F1255C3AeB6bEB3C1c4273bDdBD8A9a0C6a8',
    },
    token: tokens.fiwa,
    quoteToken: tokens.ada,
  },
  {
    pid: 10,
    tokenId: 11,
    category: FarmCategory.NFT,
    lpSymbol: 'FIWA-COTI LP',
    lpAddresses: {
      97: '0x34bBB4226626A75cC57Ae9cda6DD4C0aa29B0F28',
      56: '0xB4a8F1255C3AeB6bEB3C1c4273bDdBD8A9a0C6a8',
    },
    token: tokens.fiwa,
    quoteToken: tokens.ada,
  },
  {
    pid: 11,
    tokenId: 12,
    category: FarmCategory.NFT,
    lpSymbol: 'FIWA-ADAX LP',
    lpAddresses: {
      97: '0x34bBB4226626A75cC57Ae9cda6DD4C0aa29B0F28',
      56: '0xB4a8F1255C3AeB6bEB3C1c4273bDdBD8A9a0C6a8',
    },
    token: tokens.fiwa,
    quoteToken: tokens.ada,
  },
  // {
  //   pid: 0,
  //   tokenId: 1,
  //   version: 1,
  //   category: FarmCategory.CWIG,
  //   lpSymbol: 'FIWA-BTC LP',
  //   lpAddresses: {
  //     97: '0x088861eE6d0638bB32bAE2250BB23165dF7a357d',
  //     56: '0xc6c0C0c79de7324c5D092a266547eF27F9AE512D',
  //   },
  //   end: 11247137,
  //   token: tokens.cwig,
  //   quoteToken: tokens.cwig,
  // },
  // {
  //   pid: 1,
  //   tokenId: 2,
  //   version: 1,
  //   category: FarmCategory.CWIG,
  //   lpSymbol: 'FIWA-BCH LP',
  //   lpAddresses: {
  //     97: '0x4Ee0Fc4E296D9DA65DFA32bB905Da427071F9CDD',
  //     56: '0xc6c0C0c79de7324c5D092a266547eF27F9AE512D',
  //   },
  //   end: 11247137,
  //   token: tokens.cwig,
  //   quoteToken: tokens.cwig,
  // },
  // {
  //   pid: 2,
  //   tokenId: 3,
  //   version: 1,
  //   category: FarmCategory.CWIG,
  //   lpSymbol: 'FIWA-LTC LP',
  //   lpAddresses: {
  //     97: '0x739d15E34347E8C9e4eCBE993dEbb3Ae9175477A',
  //     56: '0xc6c0C0c79de7324c5D092a266547eF27F9AE512D',
  //   },
  //   end: 11247137,
  //   token: tokens.cwig,
  //   quoteToken: tokens.cwig,
  // },
  // {
  //   pid: 3,
  //   tokenId: 4,
  //   version: 1,
  //   category: FarmCategory.CWIG,
  //   lpSymbol: 'FIWA-ETH LP',
  //   lpAddresses: {
  //     97: '0xEA8a9a77FcCC965D9F36b912256FF8F3B8746766',
  //     56: '0xc6c0C0c79de7324c5D092a266547eF27F9AE512D',
  //   },
  //   end: 11247137,
  //   token: tokens.cwig,
  //   quoteToken: tokens.cwig,
  // },
  // {
  //   pid: 4,
  //   tokenId: 5,
  //   version: 1,
  //   category: FarmCategory.CWIG,
  //   lpSymbol: 'FIWA-BNB LP',
  //   lpAddresses: {
  //     97: '0x0a2270b1A4d3c7A7E9d995D8dEa065cfEd8b169f',
  //     56: '0xc6c0C0c79de7324c5D092a266547eF27F9AE512D',
  //   },
  //   end: 11247137,
  //   token: tokens.cwig,
  //   quoteToken: tokens.cwig,
  // },
  // {
  //   pid: 5,
  //   tokenId: 6,
  //   version: 1,
  //   category: FarmCategory.CWIG,
  //   lpSymbol: 'FIWA-UNI LP',
  //   lpAddresses: {
  //     97: '0x45dd0633d8D6F996b0FE6F5cAE35787e80e63c8f',
  //     56: '0xc6c0C0c79de7324c5D092a266547eF27F9AE512D',
  //   },
  //   end: 11247137,
  //   token: tokens.cwig,
  //   quoteToken: tokens.cwig,
  // },
  // {
  //   pid: 0,
  //   tokenId: 1,
  //   version: 1,
  //   category: FarmCategory.FIWA,
  //   lpSymbol: 'FIWA-BTC LP',
  //   lpAddresses: {
  //     97: '0x088861eE6d0638bB32bAE2250BB23165dF7a357d',
  //     56: '0x308d6B943b439f33531353DeEc63C3e5E71B5662',
  //   },
  //   token: tokens.fiwa,
  //   quoteToken: tokens.fiwa,
  // },
  // {
  //   pid: 1,
  //   tokenId: 2,
  //   version: 1,
  //   category: FarmCategory.FIWA,
  //   lpSymbol: 'FIWA-BCH LP',
  //   lpAddresses: {
  //     97: '0x4Ee0Fc4E296D9DA65DFA32bB905Da427071F9CDD',
  //     56: '0x308d6B943b439f33531353DeEc63C3e5E71B5662',
  //   },
  //   token: tokens.fiwa,
  //   quoteToken: tokens.fiwa,
  // },
  // {
  //   pid: 2,
  //   tokenId: 3,
  //   version: 1,
  //   category: FarmCategory.FIWA,
  //   lpSymbol: 'FIWA-LTC LP',
  //   lpAddresses: {
  //     97: '0x739d15E34347E8C9e4eCBE993dEbb3Ae9175477A',
  //     56: '0x308d6B943b439f33531353DeEc63C3e5E71B5662',
  //   },
  //   token: tokens.fiwa,
  //   quoteToken: tokens.fiwa,
  // },
  // {
  //   pid: 3,
  //   tokenId: 4,
  //   version: 1,
  //   category: FarmCategory.FIWA,
  //   lpSymbol: 'FIWA-ETH LP',
  //   lpAddresses: {
  //     97: '0xEA8a9a77FcCC965D9F36b912256FF8F3B8746766',
  //     56: '0x308d6B943b439f33531353DeEc63C3e5E71B5662',
  //   },
  //   token: tokens.fiwa,
  //   quoteToken: tokens.fiwa,
  // },
  // {
  //   pid: 4,
  //   tokenId: 5,
  //   version: 1,
  //   category: FarmCategory.FIWA,
  //   lpSymbol: 'FIWA-BNB LP',
  //   lpAddresses: {
  //     97: '0x0a2270b1A4d3c7A7E9d995D8dEa065cfEd8b169f',
  //     56: '0x308d6B943b439f33531353DeEc63C3e5E71B5662',
  //   },
  //   token: tokens.fiwa,
  //   quoteToken: tokens.fiwa,
  // },
  // {
  //   pid: 5,
  //   tokenId: 6,
  //   version: 1,
  //   category: FarmCategory.FIWA,
  //   lpSymbol: 'FIWA-UNI LP',
  //   lpAddresses: {
  //     97: '0x45dd0633d8D6F996b0FE6F5cAE35787e80e63c8f',
  //     56: '0x308d6B943b439f33531353DeEc63C3e5E71B5662',
  //   },
  //   token: tokens.fiwa,
  //   quoteToken: tokens.fiwa,
  // },
  {
    pid: 0,
    tokenId: 1,
    version: 2,
    category: FarmCategory.CWIG,
    lpSymbol: 'FIWA-BTC LP',
    lpAddresses: {
      97: '0xd13cbF66c0565C15a32f70B9850E70CfFc659Fd4',
      56: '0x51ae43e569144582e15361c342c51875A80F0608',
    },
    token: tokens.cwig,
    quoteToken: tokens.cwig,
  },
  {
    pid: 1,
    tokenId: 2,
    version: 2,
    category: FarmCategory.CWIG,
    lpSymbol: 'FIWA-BCH LP',
    lpAddresses: {
      97: '0xd13cbF66c0565C15a32f70B9850E70CfFc659Fd4',
      56: '0x51ae43e569144582e15361c342c51875A80F0608',
    },
    token: tokens.cwig,
    quoteToken: tokens.cwig,
  },
  {
    pid: 2,
    tokenId: 3,
    version: 2,
    category: FarmCategory.CWIG,
    lpSymbol: 'FIWA-LTC LP',
    lpAddresses: {
      97: '0xd13cbF66c0565C15a32f70B9850E70CfFc659Fd4',
      56: '0x51ae43e569144582e15361c342c51875A80F0608',
    },
    token: tokens.cwig,
    quoteToken: tokens.cwig,
  },
  {
    pid: 3,
    tokenId: 4,
    version: 2,
    category: FarmCategory.CWIG,
    lpSymbol: 'FIWA-ETH LP',
    lpAddresses: {
      97: '0xd13cbF66c0565C15a32f70B9850E70CfFc659Fd4',
      56: '0x51ae43e569144582e15361c342c51875A80F0608',
    },
    token: tokens.cwig,
    quoteToken: tokens.cwig,
  },
  {
    pid: 4,
    tokenId: 5,
    version: 2,
    category: FarmCategory.CWIG,
    lpSymbol: 'FIWA-BNB LP',
    lpAddresses: {
      97: '0xd13cbF66c0565C15a32f70B9850E70CfFc659Fd4',
      56: '0x51ae43e569144582e15361c342c51875A80F0608',
    },
    token: tokens.cwig,
    quoteToken: tokens.cwig,
  },
  {
    pid: 5,
    tokenId: 6,
    version: 2,
    category: FarmCategory.CWIG,
    lpSymbol: 'FIWA-UNI LP',
    lpAddresses: {
      97: '0xd13cbF66c0565C15a32f70B9850E70CfFc659Fd4',
      56: '0x51ae43e569144582e15361c342c51875A80F0608',
    },
    token: tokens.cwig,
    quoteToken: tokens.cwig,
  },
  {
    pid: 6,
    tokenId: 7,
    version: 2,
    category: FarmCategory.CWIG,
    lpSymbol: 'FIWA-SOL LP',
    lpAddresses: {
      97: '0xd13cbF66c0565C15a32f70B9850E70CfFc659Fd4',
      56: '0x51ae43e569144582e15361c342c51875A80F0608',
    },
    token: tokens.cwig,
    quoteToken: tokens.ada,
  },
  {
    pid: 7,
    tokenId: 8,
    version: 2,
    category: FarmCategory.CWIG,
    lpSymbol: 'FIWA-LINK LP',
    lpAddresses: {
      97: '0xd13cbF66c0565C15a32f70B9850E70CfFc659Fd4',
      56: '0x51ae43e569144582e15361c342c51875A80F0608',
    },
    token: tokens.cwig,
    quoteToken: tokens.link,
  },
  {
    pid: 8,
    tokenId: 9,
    version: 2,
    category: FarmCategory.CWIG,
    lpSymbol: 'FIWA-WAVES LP',
    lpAddresses: {
      97: '0xd13cbF66c0565C15a32f70B9850E70CfFc659Fd4',
      56: '0x51ae43e569144582e15361c342c51875A80F0608',
    },
    token: tokens.cwig,
    quoteToken: tokens.link,
  },
  {
    pid: 9,
    tokenId: 10,
    version: 2,
    category: FarmCategory.CWIG,
    lpSymbol: 'FIWA-ADA LP',
    lpAddresses: {
      97: '0xd13cbF66c0565C15a32f70B9850E70CfFc659Fd4',
      56: '0x51ae43e569144582e15361c342c51875A80F0608',
    },
    token: tokens.cwig,
    quoteToken: tokens.ada,
  },
  {
    pid: 10,
    tokenId: 11,
    version: 2,
    category: FarmCategory.CWIG,
    lpSymbol: 'FIWA-COTI LP',
    lpAddresses: {
      97: '0xd13cbF66c0565C15a32f70B9850E70CfFc659Fd4',
      56: '0x51ae43e569144582e15361c342c51875A80F0608',
    },
    token: tokens.cwig,
    quoteToken: tokens.link,
  },
  {
    pid: 11,
    tokenId: 12,
    version: 2,
    category: FarmCategory.CWIG,
    lpSymbol: 'FIWA-ADAX LP',
    lpAddresses: {
      97: '0xd13cbF66c0565C15a32f70B9850E70CfFc659Fd4',
      56: '0x51ae43e569144582e15361c342c51875A80F0608',
    },
    token: tokens.cwig,
    quoteToken: tokens.link,
  },
  {
    pid: 0,
    tokenId: 1,
    version: 2,
    category: FarmCategory.FIWA,
    lpSymbol: 'FIWA-BTC LP',
    lpAddresses: {
      97: '0xC611033C82b1eDbAa271989A16AAAe9AC8af28Ec',
      56: '0x302a0d54dD3CE8a31FD156cA6a5EA0e0cCbccc2E',
    },
    token: tokens.fiwa,
    quoteToken: tokens.fiwa,
  },
  {
    pid: 1,
    tokenId: 2,
    version: 2,
    category: FarmCategory.FIWA,
    lpSymbol: 'FIWA-BCH LP',
    lpAddresses: {
      97: '0xC611033C82b1eDbAa271989A16AAAe9AC8af28Ec',
      56: '0x302a0d54dD3CE8a31FD156cA6a5EA0e0cCbccc2E',
    },
    token: tokens.fiwa,
    quoteToken: tokens.fiwa,
  },
  {
    pid: 2,
    tokenId: 3,
    version: 2,
    category: FarmCategory.FIWA,
    lpSymbol: 'FIWA-LTC LP',
    lpAddresses: {
      97: '0xC611033C82b1eDbAa271989A16AAAe9AC8af28Ec',
      56: '0x302a0d54dD3CE8a31FD156cA6a5EA0e0cCbccc2E',
    },
    token: tokens.fiwa,
    quoteToken: tokens.fiwa,
  },
  {
    pid: 3,
    tokenId: 4,
    version: 2,
    category: FarmCategory.FIWA,
    lpSymbol: 'FIWA-ETH LP',
    lpAddresses: {
      97: '0xC611033C82b1eDbAa271989A16AAAe9AC8af28Ec',
      56: '0x302a0d54dD3CE8a31FD156cA6a5EA0e0cCbccc2E',
    },
    token: tokens.fiwa,
    quoteToken: tokens.fiwa,
  },
  {
    pid: 4,
    tokenId: 5,
    version: 2,
    category: FarmCategory.FIWA,
    lpSymbol: 'FIWA-BNB LP',
    lpAddresses: {
      97: '0xC611033C82b1eDbAa271989A16AAAe9AC8af28Ec',
      56: '0x302a0d54dD3CE8a31FD156cA6a5EA0e0cCbccc2E',
    },
    token: tokens.fiwa,
    quoteToken: tokens.fiwa,
  },
  {
    pid: 5,
    tokenId: 6,
    version: 2,
    category: FarmCategory.FIWA,
    lpSymbol: 'FIWA-UNI LP',
    lpAddresses: {
      97: '0xC611033C82b1eDbAa271989A16AAAe9AC8af28Ec',
      56: '0x302a0d54dD3CE8a31FD156cA6a5EA0e0cCbccc2E',
    },
    token: tokens.fiwa,
    quoteToken: tokens.fiwa,
  },
  {
    pid: 6,
    tokenId: 7,
    version: 2,
    category: FarmCategory.FIWA,
    lpSymbol: 'FIWA-SOL LP',
    lpAddresses: {
      97: '0xC611033C82b1eDbAa271989A16AAAe9AC8af28Ec',
      56: '0x302a0d54dD3CE8a31FD156cA6a5EA0e0cCbccc2E',
    },
    token: tokens.fiwa,
    quoteToken: tokens.fiwa,
  },
  {
    pid: 7,
    tokenId: 8,
    version: 2,
    category: FarmCategory.FIWA,
    lpSymbol: 'FIWA-LINK LP',
    lpAddresses: {
      97: '0xC611033C82b1eDbAa271989A16AAAe9AC8af28Ec',
      56: '0x302a0d54dD3CE8a31FD156cA6a5EA0e0cCbccc2E',
    },
    token: tokens.fiwa,
    quoteToken: tokens.fiwa,
  },
  {
    pid: 8,
    tokenId: 9,
    version: 2,
    category: FarmCategory.FIWA,
    lpSymbol: 'FIWA-WAVES LP',
    lpAddresses: {
      97: '0xC611033C82b1eDbAa271989A16AAAe9AC8af28Ec',
      56: '0x302a0d54dD3CE8a31FD156cA6a5EA0e0cCbccc2E',
    },
    token: tokens.fiwa,
    quoteToken: tokens.fiwa,
  },
  {
    pid: 9,
    tokenId: 10,
    version: 2,
    category: FarmCategory.FIWA,
    lpSymbol: 'FIWA-ADA LP',
    lpAddresses: {
      97: '0xC611033C82b1eDbAa271989A16AAAe9AC8af28Ec',
      56: '0x302a0d54dD3CE8a31FD156cA6a5EA0e0cCbccc2E',
    },
    token: tokens.fiwa,
    quoteToken: tokens.fiwa,
  },
  {
    pid: 10,
    tokenId: 11,
    version: 2,
    category: FarmCategory.FIWA,
    lpSymbol: 'FIWA-COTI LP',
    lpAddresses: {
      97: '0xC611033C82b1eDbAa271989A16AAAe9AC8af28Ec',
      56: '0x302a0d54dD3CE8a31FD156cA6a5EA0e0cCbccc2E',
    },
    token: tokens.fiwa,
    quoteToken: tokens.fiwa,
  },
  {
    pid: 11,
    tokenId: 12,
    version: 2,
    category: FarmCategory.FIWA,
    lpSymbol: 'FIWA-ADAX LP',
    lpAddresses: {
      97: '0xC611033C82b1eDbAa271989A16AAAe9AC8af28Ec',
      56: '0x302a0d54dD3CE8a31FD156cA6a5EA0e0cCbccc2E',
    },
    token: tokens.fiwa,
    quoteToken: tokens.fiwa,
  },
]

export default farms
