// @ts-nocheck
import React, { useState, createContext } from 'react'

export const DataContext = createContext()

export default ({ children }) => {
  const [logined, setLogined] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loginType, setLoginType] = useState({ title: '', icon: '' })

  React.useEffect(() => {
    setLoading(true)
  }, [])

  return (
    <DataContext.Provider
      value={{
        logined,
        setLogined,
        loading,
        setLoading,
        loginType,
        setLoginType,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}
