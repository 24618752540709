import React from 'react'
import styled from 'styled-components'
import Media from 'utils/media'
import { Modal, ModalProps } from '@pancakeswap/uikit'

/**
 * Over write from: https://pancakeswap.github.io/pancake-uikit/?path=/docs/widgets-modal--with-back-button
 */
const ModalLogin: React.FC<ModalProps> = (props) => {
  const { children, ...rest } = props

  return (
    <StyledModalLogin
      {...rest}
      //
    >
      {children}
    </StyledModalLogin>
  )
}

const StyledModalLogin = styled(Modal)<any>`
  background: linear-gradient(121.2deg, rgba(157, 0, 183, 0.85) -12.69%, rgba(0, 13, 135, 0.85) 100%);
  border: 2px solid #ffffff;
  box-shadow: 6px 5px 0px #3c0883;
  border-radius: 10px;
  width: 480px;
  height: 452px;
  position: relative;
  padding: 20px 24px;
  background-clip: padding-box;
  pointer-events: auto;

  ${Media.lessThan(Media.SIZE.MD)} {
    width: 335px;
    height: 356px;
    background: linear-gradient(121.2deg, rgba(157, 0, 183, 0.85) -12.69%, rgba(0, 13, 135, 0.85) 100%);
    border: 2px solid #ffffff;
    box-shadow: 6px 5px 0px #3c0883;
    border-radius: 10px;
  }

  & > div {
    button svg {
      fill: #fff;
    }
    &:nth-child(1) {
      /* height: 70px;
      padding: 0 7px 0 25px; */
      height: 20px;
      padding: 0;
      display: flex;
      align-items: center;
      margin-bottom: 28px;
      border-bottom: none !important;

      white-space: inherit;
      & > div > h2 {
        letter-spacing: 0.02em;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 20px !important;
        line-height: 18px;
        color: #ffffff;
        ${Media.lessThan(Media.SIZE.MD)} {
          font-size: 12px !important;
        }
      }
      & > button {
        width: max-content;
      }
      & > button > svg {
        fill: #ffffff;
        color: white;
      }
    }
  }
`

export default ModalLogin
